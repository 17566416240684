import React from "react";
import async from 'async';
import Update from 'immutability-helper';
import Select from 'react-select';
import html2canvas from 'html2canvas';

// reactstrap components
import {
	Row, Col, Card, Button, Input, Label, CustomInput,
	Spinner
} from "reactstrap";

import dayjs from 'dayjs';
import { Rx, Gx } from "helper/ApiCaller.js";
import DatePicker from 'react-datepicker';
import Dropzone from 'react-dropzone';
import store from 'store';
import Swal from 'sweetalert2';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import langEnUs from 'variables/lang-enUS.json';
import langMyMr from 'variables/lang-myMR.json';

var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)
var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)
var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

function rn(max) {
	return Math.floor(Math.random() * Math.floor(max));
}


class ClaimSubmitForm extends React.Component {
	constructor(props) {
		super(props);
		this.dateFormat = 'DD/MM/YYYY';
		this.companyName = "Company";

		this.Rx = new Rx(this.props.history);
		this.Gx = new Gx(this.props.history);
		this.lang = {
						"en-US":langEnUs,
						"my-MR":langMyMr,
					}

		this.lmt2233 = [
			{
				"SCMA_OID_BEN_TYPE": "BENEFIT_TYPE_PA",
				"BEHD_OID": "20146",
				"BEHL_OID": "21146",
				"CODE_DESC": "Loss of Sight 1 or Both Eyes",
				"UPD_USER": "SYSTEM",
				"CRT_DATE": "2005-06-28 20:47:41.0",
				"CRT_USER": "SYSTEM",
				"BEN_HEAD": "EYES",
				"LANG_CODE": "en",
				"UPD_DATE": "2005-06-28 20:47:41.0"
			},
			{
				"SCMA_OID_BEN_TYPE": "BENEFIT_TYPE_PA",
				"BEHD_OID": "20295",
				"BEHL_OID": "21275",
				"CODE_DESC": "Loss of all toes on one foot",
				"UPD_USER": "SYSTEM",
				"CRT_DATE": "2005-08-19 17:11:43.0",
				"CRT_USER": "SYSTEM",
				"BEN_HEAD": "LATO",
				"LANG_CODE": "en",
				"UPD_DATE": "2005-08-19 17:11:43.0"
			},
			{
				"SCMA_OID_BEN_TYPE": "BENEFIT_TYPE_PA",
				"BEHD_OID": "20296",
				"BEHL_OID": "21276",
				"CODE_DESC": "Loss of 4 fingers of one hand",
				"UPD_USER": "SYSTEM",
				"CRT_DATE": "2005-08-19 17:11:43.0",
				"CRT_USER": "SYSTEM",
				"BEN_HEAD": "LF4",
				"LANG_CODE": "en",
				"UPD_DATE": "2005-08-19 17:11:43.0"
			},

			{
				"SCMA_OID_BEN_TYPE": "BENEFIT_TYPE_PA",
				"BEHD_OID": "20297",
				"BEHL_OID": "21277",
				"CODE_DESC": "Loss of thumb & 4 fin of one h",
				"UPD_USER": "SYSTEM",
				"CRT_DATE": "2005-08-19 17:11:43.0",
				"CRT_USER": "SYSTEM",
				"BEN_HEAD": "LF5",
				"LANG_CODE": "en",
				"UPD_DATE": "2005-08-19 17:11:43.0"
			},
			{
				"SCMA_OID_BEN_TYPE": "BENEFIT_TYPE_PA",
				"BEHD_OID": "20298",
				"BEHL_OID": "21278",
				"CODE_DESC": "Total loss of hear one ear",
				"UPD_USER": "SYSTEM",
				"CRT_DATE": "2005-08-19 17:11:43.0",
				"CRT_USER": "SYSTEM",
				"BEN_HEAD": "LHR1",
				"LANG_CODE": "en",
				"UPD_DATE": "2005-08-19 17:11:43.0"
			},
			{
				"SCMA_OID_BEN_TYPE": "BENEFIT_TYPE_PA",
				"BEHD_OID": "20149",
				"BEHL_OID": "21149",
				"CODE_DESC": "Death",
				"UPD_USER": "SYSTEM",
				"CRT_DATE": "2005-06-28 20:47:41.0",
				"CRT_USER": "SYSTEM",
				"BEN_HEAD": "LIFE",
				"LANG_CODE": "en",
				"UPD_DATE": "2005-06-28 20:47:41.0"
			},
			{
				"SCMA_OID_BEN_TYPE": "BENEFIT_TYPE_PA",
				"BEHD_OID": "20148",
				"BEHL_OID": "21148",
				"CODE_DESC": "Loss of Limb 1 or Both Limbs",
				"UPD_USER": "SYSTEM",
				"CRT_DATE": "2005-06-28 20:47:41.0",
				"CRT_USER": "SYSTEM",
				"BEN_HEAD": "LLIM",
				"LANG_CODE": "en",
				"UPD_DATE": "2005-06-28 20:47:41.0"
			},
			{
				"SCMA_OID_BEN_TYPE": "BENEFIT_TYPE_PA",
				"BEHD_OID": "20303",
				"BEHL_OID": "21283",
				"CODE_DESC": "Total & irrem loss of speech",
				"UPD_USER": "SYSTEM",
				"CRT_DATE": "2005-08-19 17:11:43.0",
				"CRT_USER": "SYSTEM",
				"BEN_HEAD": "LSP",
				"LANG_CODE": "en",
				"UPD_DATE": "2005-08-19 17:11:43.0"
			},
			{
				"SCMA_OID_BEN_TYPE": "BENEFIT_TYPE_PA",
				"BEHD_OID": "20147",
				"BEHL_OID": "21147",
				"CODE_DESC": "Total Disability of 1Limb",
				"UPD_USER": "SYSTEM",
				"CRT_DATE": "2005-06-28 20:47:41.0",
				"CRT_USER": "SYSTEM",
				"BEN_HEAD": "LU1L",
				"LANG_CODE": "en",
				"UPD_DATE": "2005-06-28 20:47:41.0"
			},
			{
				"SCMA_OID_BEN_TYPE": "BENEFIT_TYPE_PA",
				"BEHD_OID": "20151",
				"BEHL_OID": "21151",
				"CODE_DESC": "Other Permanent Total Disablement",
				"UPD_USER": "SYSTEM",
				"CRT_DATE": "2005-06-28 20:47:41.0",
				"CRT_USER": "SYSTEM",
				"BEN_HEAD": "TODI",
				"LANG_CODE": "en",
				"UPD_DATE": "2005-06-28 20:47:41.0"
			},
			{
				"SCMA_OID_BEN_TYPE": "BENEFIT_TYPE_IP",
				"BEHD_OID": "20028",
				"BEHL_OID": "21028",
				"CODE_DESC": "Intensive Care Unit",
				"UPD_USER": "SYSTEM",
				"CRT_DATE": "2005-06-28 20:47:41.0",
				"CRT_USER": "SYSTEM",
				"BEN_HEAD": "ICU",
				"LANG_CODE": "en",
				"UPD_DATE": "2005-06-28 20:47:41.0"
			},
			{
				"SCMA_OID_BEN_TYPE": "BENEFIT_TYPE_IP",
				"BEHD_OID": "21845",
				"BEHL_OID": "22800",
				"CODE_DESC": "Hospital Miscellaneous Charges",
				"UPD_USER": "SYSTEM",
				"CRT_DATE": "2018-08-28 10:28:19.0",
				"CRT_USER": "SYSTEM",
				"BEN_HEAD": "IMIS",
				"LANG_CODE": "en",
				"UPD_DATE": "2018-08-28 10:28:19.0"
			},
			{
				"SCMA_OID_BEN_TYPE": "BENEFIT_TYPE_IP",
				"BEHD_OID": "20380",
				"BEHL_OID": "21377",
				"CODE_DESC": "Hospital expenses",
				"UPD_USER": "SYSTEM",
				"CRT_DATE": "2013-08-22 10:15:38.0",
				"CRT_USER": "SYSTEM",
				"BEN_HEAD": "HE",
				"LANG_CODE": "en",
				"UPD_DATE": "2013-08-22 10:15:38.0"
			},
			{
				"SCMA_OID_BEN_TYPE": "BENEFIT_TYPE_IP",
				"BEHD_OID": "20476",
				"BEHL_OID": "21472",
				"CODE_DESC": "Nursing fee",
				"UPD_USER": "SYSTEM",
				"CRT_DATE": "2013-11-04 12:26:34.0",
				"CRT_USER": "SYSTEM",
				"BEN_HEAD": "NF",
				"LANG_CODE": "en",
				"UPD_DATE": "2013-11-04 12:26:34.0"
			},
			{
				"SCMA_OID_BEN_TYPE": "BENEFIT_TYPE_IP",
				"BEHD_OID": "20055",
				"BEHL_OID": "21055",
				"CODE_DESC": "Room & Board",
				"UPD_USER": "SYSTEM",
				"CRT_DATE": "2005-06-28 20:47:41.0",
				"CRT_USER": "SYSTEM",
				"BEN_HEAD": "RB",
				"LANG_CODE": "en",
				"UPD_DATE": "2005-06-28 20:47:41.0"
			},
			{
				"SCMA_OID_BEN_TYPE": "BENEFIT_TYPE_IP",
				"BEHD_OID": "22157",
				"BEHL_OID": "23095",
				"CODE_DESC": "Surgeon visit charges in IP",
				"UPD_USER": "SYSTEM",
				"CRT_DATE": "2022-10-29 16:51:31.0",
				"CRT_USER": "SYSTEM",
				"BEN_HEAD": "SVC",
				"LANG_CODE": "en",
				"UPD_DATE": "2022-10-29 16:51:31.0"
			}
		]

		this.placeClaimTemplate = {
			barcodeId: "",
			operator: "",
			brokerRefNo: "",
			processed: "",
			voided: "",
			bindingClaimNo: "",
			username: "",
			clientId: "",
			channel: "",
			claimant: "",
			applyQuote: "",
			applyInPat: "",
			memberNo: "",
			companyRefNo: "",
			materials: "",
			materialsChecksum: "",
			signatureFilename: "",
			createdAt: "",
			drafted: "",
			notified: "",
			archived: "",
			amendmentToken: "",
			amendments: "",
			refNo: "",
			imageName: "",
			status: "",
			statusCode: "",
			claimNo: "",
			pdfChecksum: "",
			comments: "",
			claimStatus: "",
			claimFileCloseDate: "",
			scannerId: "",
			scanId: "",
			_id: "",
			domainId: "",
			updated: "",
			updatedBy: "",
			createdBy: ""
		}

		this.banks = [
			"BDO",
			"Metrobank",
			"BPI",
			"Eastwest",
			"UnionBank",
			"Other Banks",
		];

		let cachedClaim = {};

		if (store.get("claimDetail")) { //for dev handy 
			cachedClaim = store.get("claimDetail");
			if (cachedClaim.attachments) {
				cachedClaim.files = [];
				cachedClaim.attachments.forEach(att => {
					cachedClaim.files.push({
						...att
					})
				})
			}
			cachedClaim.trackNo = "TX"+uuidv4().split("-")[0].toUpperCase();
			cachedClaim.barcodeId = "OL"+uuidv4().split("-")[0].toUpperCase();
			console.log(cachedClaim);
		}
		this.clUser = store.get("clUser");
		
		this.state = {
			...this.props.match.params,
			//my-MR
			lang: store.get("lang") ?? "en-US",

			benHeadList: [],
			sysCodeList: [],
			claimDocList: [],
			provList: [],
			files: [],
			ICD10Cat: [],
			policies: [],
			policy: null,
			memberNo: null,
			//when member have no policy, load product plan to allow the claim form to display/demo @20221031
			demoProductPlan: null,
			plans: [],

			trackNo : "TX"+uuidv4().split("-")[0].toUpperCase(),
			barcodeId : "OL"+uuidv4().split("-")[0].toUpperCase(),

			"contactPersonName": this.clUser["Policyholder Name"] ?? "",

			"firstName": "",
			"middleName": "",
			"lastName": "",
			"externalRefNo": "",
			"brkrName": "",
			"insuranceCompany": "",
			"policyType": this.clUser?.Type ?? "I",
			"addressHome": "",
			"phoneHome": "",
			"phoneOffice": "",
			"addressOffice": "",
			"phoneMobile": "",
			"fax": "",
			idnoInsured: "",
			nationalityInsured: "",
			dobInsured: "",
			idno: "",
			nationality: "",
			dob: "",
			relWithInsured: "",
			isSameIns: "Y",
			"claimantEmail": "",

			"claimantName": "",
			"isAgreeStatment": false,

			"claimBenHead": [
				"BASICCLAIMSREQUIREMENTS",
			],
			"attachments": [],
			"attachmentDetails": [],

			"effDate": "",
			"expDate": "",
			"dateAccident": undefined,
			"timeAccident": "",
			"locationAccident": "",

			"receipts": [
				{
					"receiptNo": "",
					"details": "",
					"amt": "0",
					"ccy": "MMK",
					"benefit": ""
				}
			],

			"paymentType": "bank",
			"paymentBank": "",
			"bankBranchAccount": "",
			"bankName": "",
			"bankAccountNo": "",
			"bankAccountType": "",
			"bankSWIFT": "",
			"bankAccountAddress": "",
			"bankCcy": "",
			"bankRemark": "",

			"dateConsultation": undefined,
			"dateAdmitted": undefined,
			"dateDischarged": undefined,
			"timeAdmitted": "",
			"timeDischarged": "",
			"attendings": [
				{
					"diagnosis": "",
					"dateSymptomsFirstAppeared": "",
					"dateDiagnosedCondition": "",
					"datePrevTreatment": "",
					"drHospitalPrevTreatment": "",
					"receipt": ""
				}
			],

			"reasonAdmission": "",
			"dateSymptomsStarted": "",
			"nameSurgical": "",

			"claimType": "",
			"isOSEmergency": "N",

			"isRequiredPostoperativeConsultations": null,
			"datePostoprConsultation": undefined,

			"isOtherAffectingPresentCondition": null,
			"otherAffectingPresentCondition": "",

			"isCondDueToDentalPregnancyChildbirthMiscarriage": null,
			"condDueToDentalPregnancyChildbirthMiscarriage": "",

			"isFF": null,
			"isContinuousTreatment": null,
			"isVaccination": null,

			"isAccidentRelated": null,
			"dateAccidentRelated": undefined,
			"timeAccidentRelated": "",
			"natureAccidentRelated": "",

			"isPhysiotherapy": null,

			"isRelPrevConfinement": null,
			"dateRelPrevConfinement": "",

			"nameMainAttendingPhysicianSurgeon": "",
			"attendingHospital": "",
			"attendingPhoneNo": "",
			"attendingAddress": "",
			"attendingFaxNo": "",


				...cachedClaim,
		};

		this.policyPlan = null;
		this.policyPlanBenHead = this.lmt2233.map(l => l.BEN_HEAD);
		this.selectedMember = null;
		this.user = store.get("user");
		console.log(this.user);

		this.handleChange = this.handleChange.bind(this);
		this.handleBenHeadChange = this.handleBenHeadChange.bind(this);
		this.addRecpipt = this.addRecpipt.bind(this);
		this.addAttending = this.addAttending.bind(this);
		this.handleArrayChange = this.handleArrayChange.bind(this);
		this.onDrop = this.onDrop.bind(this);
		this.submitClaims = this.submitClaims.bind(this);
		this.buildNCClaims = this.buildNCClaims.bind(this);
		this.getClaimById = this.getClaimById.bind(this);
		this.removeFile = this.removeFile.bind(this);


	}

	componentDidMount() {
		this.props.isFetching();
		Promise.all([
			this.getJsonFile("/data/PROV.json"),
			this.getJsonFile("/data/sysCode.json"),
			this.getJsonFile("/data/BEN_HEAD.json"),
			this.getJsonFile("/data/phTravelClaimAttList.json"),
			this.getJsonFile("/data/ICD10.json"),
			this.getProductPlan(),
			this.getPolicies(),
			this.getClaimById(this.state.claimId)
		])
			.then(function ([provList, sysCodeList, benHeadList, claimDocList, ICD10, plans, policies, claim]) {
				console.log(plans)
				/*
				let planBen = plan.map(p=>{
					p.benefits = bh.filter(b=>b.PLANID === p.planId).map(b=>b.BEN_HEAD);
					return p;
				});
				
				console.log(JSON.stringify(planBen,null,2));
				let ICD10X  = ICD10.map(i => {
					if(i.cat === "ICD10_2016_CONDITION"){
						i.type = "chapter";
					}else if(i.code.indexOf("-") !== -1){
						i.type = "section";
					}else if(i.code.indexOf(".") === -1){
						i.type = "category";
					}else if(i.code.indexOf(".") !== -1){
						i.type = "codes";
					}
					return i;
				});
				*/
				let claimUserInput = {};
				//console.log("claim", claim);
				if (claim?._id) {
					claimUserInput = { ...claim.userInput };
					console.log("claimUserInput", claimUserInput);

					this.policyPlan = policies.find(p => p.policy.externalRefNo === claimUserInput.externalRefNo);
					//this.policyPlanBenHead = plans.find(p => p.planId === this.policyPlan.productPlan.planId + "-" + this.policyPlan.productPlan.revNo)?.benefits;
					claimUserInput.policy = this.policyPlan.refNo;

					this.selectedMember = this.policyPlan.memberPlans.find(m => m.membership.memberNo === claim.memberNo);
					claimUserInput.memberNo = claim.memberNo;

					if (claimUserInput.attachments) {
						claimUserInput.files = [];
						claimUserInput.attachments.forEach(att => {
							claimUserInput.files.push({
								...att
							})
						})
					}
				}

				let ICD10Cat = ICD10.filter(code => code.type === "category");
				console.log("ICD10",ICD10Cat.length);

				if (this.state.policy) {
					this.policyPlan = policies.find(p => p.refNo === this.state.policy) ?? null;
					if (this.policyPlan) {
						//this.policyPlanBenHead = plans.find(p => p.planId === this.policyPlan.productPlan.planId + "-" + this.policyPlan.productPlan.revNo)?.benefits;
					}
				}

				//console.log("this.props.location.query", this.props.location);
				let externalRefNo = new URLSearchParams(this.props.location.search).get("externalRefNo");
				if (externalRefNo) {
					this.policyPlan = policies.find(p => p.policy.externalRefNo === externalRefNo);
					//this.policyPlanBenHead = plans.find(p => p.planId === this.policyPlan.productPlan.planId + "-" + this.policyPlan.productPlan.revNo)?.benefits;
					claimUserInput.policy = this.policyPlan.refNo;
				}

				this.setState({
					provList,
					sysCodeList,
					benHeadList,
					claimDocList,
					ICD10Cat,
					policies,
					plans,
					...claimUserInput
				}, () => {
					this.props.isFetching(false);
				});

			}.bind(this));

		if (this.props.setPageTitle)
			this.props.setPageTitle("Claim Submit");
	}



	getProductPlan = async () => {
		let result = [];
		

		return result;
	}


	getClaimById = async (id = "") => {
		let result = {};
	

		return result;
	}



	getClaims = async () => {


		return Array.from({ length: 300 }, (v, i) => (
			{
				claimNo: 1000000 + rn(9999999),
				memberName: "XXXX XXXX",
				incurredDate: dayjs().subtract(rn(30), "day"),
				status: "Paid"
			}
		));
	}

	getPolicies = async () => {
		let result = [];
		let clUser = store.get("clUser");
		if (clUser) {
			console.log(clUser)
			result = clUser.members.reduce((a, m) => {
				let pocyIdx = a.findIndex(p => p["Policy No."] === m["Policy No."]);
				if (pocyIdx === -1) {
					return [...a, {
						externalRefNo: m["Policy No."],
						desc: m["Policy Plan Desc."],
						refNo: m.id.POPL_OID,
						effDate : m.effDate,
						expDate : m.expDate,
						memberPlans: [
							{
								memberNo: m["Member No."],
								mbrFirstName: m["Member Name"],
								mbrLastName: "",
								dob: m["DOB"],
								idno: m["ID Card / Passport No."]
							}
						]
					}]
				} else {
					a[pocyIdx].memberPlans.push({
						memberNo: m["Member No."],
						mbrFirstName: m["Member Name"],
						mbrLastName: "",
						dob: m["DOB"],
						idno: m["ID Card / Passport No."]
					})
				}
				return a;
			}, [])
		}
		console.log(result);
		return result;

	}

	getPolicyDetail = async () => {

		let result = [];

		await this.Rx.get("/dataSample/vw_bp_broker_policy_detail.json")
			.then(function (response) {
				//console.log(response.data);
				result = response.data;
			}).catch(function (e) {
				this.props.notify(e?.response?.data.message.toString() ?? e.toString());
			}.bind(this));

		if (result.length > 0)
			return result[0];

		return {};
	}

	getJsonFile = async (path) => {

		let result = [];

		await this.Rx.get(path)
			.then(function (response) {
				//console.log(response.data);
				result = response.data;
			}).catch(function (e) {
				this.props.notify(e?.response?.data.message.toString() ?? e.toString());
			}.bind(this));


		return result;

	}

	handleChange(e) {
		let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
		let rest = {};

		if (value === "YN_Y") value = true;
		if (value === "YN_N") value = false;

		if (e.target.name === "policy") {
			console.log(this.state.policies)
			this.policyPlan = this.state.policies.find(p => p.refNo === value) ?? null;
			//this.policyPlanBenHead = this.state.plans.find(p => p.planId === this.policyPlan.productPlan.planId + "-" + this.policyPlan.productPlan.revNo)?.benefits;

			rest.externalRefNo = this.policyPlan.externalRefNo;
			rest.effDate = dayjs.utc(this.policyPlan.effDate,"MMDDYYYY");
			rest.expDate = dayjs.utc(this.policyPlan.expDate,"MMDDYYYY");


			//reset selected member
			this.selectedMember = null;
			rest.memberNo = "";
			console.log(this.policyPlan);
		}
		if (e.target.name === "demoProductPlan") {
			//this.policyPlanBenHead = value.benefits;
			//console.log("this.policyPlanBenHead",this.policyPlanBenHead)
		}

		if (e.target.name === "memberNo") {
			this.selectedMember = this.policyPlan.memberPlans.find(m => m.memberNo === value);
			if (this.selectedMember) {

				if (this.user?.name?.family && false) {
					rest.firstName = this.user.name.first;
					rest.lastName = this.user.name.family;
					rest.middleName = this.user.name.mid;
				} else {
					rest.firstName = this.selectedMember.mbrFirstName;
					rest.lastName = this.selectedMember.mbrLastName;
					//rest.middleName = this.selectedMember.membership.mbrMidName;
				}

				if (this.user?.mobile?.number) {
					rest.phoneMobile = this.user.mobile.country + this.user.mobile.number;
				} else {
					rest.phoneMobile = this.selectedMember.mobileNo ?? "";
				}
				rest.claimantEmail = this.user?.email?.address ?? this.selectedMember.email ?? "";

				if (this.user?.address?.line1) {
					rest.addressHome = this.user.address.line1;
					rest.addressHome += " " + this.user.address.line2;
					rest.addressHome += " " + this.user.address.line3;
					rest.addressHome += " " + this.user.address.city;
					rest.addressHome += " " + this.user.address.state;
					rest.addressHome += " " + this.user.address.zipCode;
					rest.addressHome += " " + this.state.sysCodeList.find(c => c.refNo === this.user.address.country)?.desc ?? "";
				} else {
					/*
					if( this.selectedMember.membership.homeAddr1){
						rest.addressHome = this.selectedMember.membership.homeAddr1;
						rest.addressHome += " " + this.selectedMember.membership.homeAddr2;
						rest.addressHome += " " + this.selectedMember.membership.homeAddr3;
						rest.addressHome += " " + this.selectedMember.membership.homeAddr4;
						rest.addressHome += " " + this.selectedMember.membership.homeProvince;
						rest.addressHome += " " + this.selectedMember.membership.homeZipCde;
						rest.addressHome += " " + this.state.sysCodeList.find(c=>c.refNo ===  this.selectedMember.membership.homeAddrCtry)?.desc ?? "";
					}else{
						rest.addressHome = this.selectedMember.membership.corrAddr1;
						rest.addressHome += " " + this.selectedMember.membership.corrAddr2;
						rest.addressHome += " " + this.selectedMember.membership.corrAddr3;
						rest.addressHome += " " + this.selectedMember.membership.corrAddr4;
						rest.addressHome += " " + this.selectedMember.membership.corrProvince;
						rest.addressHome += " " + this.selectedMember.membership.corrZipCde;
						rest.addressHome += " " + this.state.sysCodeList.find(c=>c.refNo ===  this.selectedMember.membership.countryCorrAddr)?.desc ?? "";
					}
					*/
				}

				if (this.user?.mobile?.number) {
					rest.phoneHome = this.user.mobile.country + this.user.mobile.number;
				} else {
					rest.phoneHome = this.selectedMember?.homeNo ?? "";
				}

				rest.phoneOffice = this.selectedMember.officeNo ?? "";
				rest.fax = this.selectedMember.faxNo ?? "";
				//console.log("this.selectedMember.membership", this.selectedMember.membership);

			}

		}

		if (e.target.name === "nameMainAttendingPhysicianSurgeon") {
			let selectedNameMainAttendingPhysicianSurgeon = null;
			selectedNameMainAttendingPhysicianSurgeon = this.state.provList.find(p => (`${p.PROV_NAME} - ${p.HOSP_NAME}` === value));
			if (selectedNameMainAttendingPhysicianSurgeon) {
				rest.attendingHospital = selectedNameMainAttendingPhysicianSurgeon.HOSP_NAME;
				rest.attendingFaxNo = selectedNameMainAttendingPhysicianSurgeon.FAX_NO;
				rest.attendingPhoneNo = selectedNameMainAttendingPhysicianSurgeon.PHONE1 ??
					selectedNameMainAttendingPhysicianSurgeon.PHONE2 ??
					selectedNameMainAttendingPhysicianSurgeon.PHONE3;
				rest.attendingAddress = selectedNameMainAttendingPhysicianSurgeon.ADDR1;
				if (selectedNameMainAttendingPhysicianSurgeon.ADDR2 !== "") {
					rest.attendingAddress += ", " + selectedNameMainAttendingPhysicianSurgeon.ADDR2;
				}
				if (selectedNameMainAttendingPhysicianSurgeon.ADDR3 !== "") {
					rest.attendingAddress += ", " + selectedNameMainAttendingPhysicianSurgeon.ADDR3;
				}
				if (selectedNameMainAttendingPhysicianSurgeon.ADDR4 !== "") {
					rest.attendingAddress += ", " + selectedNameMainAttendingPhysicianSurgeon.ADDR4;
				}
			}
		}

		if (e.target.name.indexOf("attachmentDetails") !== -1) {
			let aidx = 0;
			let name = e.target.dataset.bhname;

			console.log("attachmentDetails", name);
			aidx = this.state.attachmentDetails.findIndex(a => a.name === name);

			if (this.state.attachmentDetails.length === 0 || aidx < 0) {
				this.setState(Update(this.state, {
					attachmentDetails: {
						$push: [
							{ name, value }
						]
					}
				}));
			} else {
				this.setState(Update(this.state, {
					attachmentDetails: {
						$splice: [[aidx, 1,
							{ name, value }
						]]
					}
				}));
				return;
			}
		}

		this.setState({
			...rest,
			[e.target.name]: value
		});
	}



	setDates = (d, targetName) => {
		if (!d) return;
		let value = this.state[targetName];


		if (d) {
			value = dayjs.utc(dayjs(d).format("YYYY-MM-DD")).startOf('day').format();
		} else {
			value = undefined;
		}

		this.setState({ [targetName]: value });
	}

	handleBenHeadChange(e) {
		let claimBenHead = [...this.state.claimBenHead];
		let bh = e.target.name;
		let bhIdx = claimBenHead.indexOf(bh);

		if (bh === "BASICCLAIMSREQUIREMENTS")
			return;

		if (e.target.checked) {
			if (bhIdx === -1)
				claimBenHead.push(bh);
		} else {
			if (bhIdx !== -1)
				claimBenHead.splice(bhIdx, 1);
		}

		//console.log(claimBenHead);
		this.setState({ claimBenHead });
	}

	handleArrayChange(e) {
		let name = e.target.name ?? e.currentTarget.name ?? e.currentTarget.dataset.name;
		let value = e.currentTarget?.value ?? e.target.value;
		let idx = e.target.dataset.idx ?? e.currentTarget.dataset.idx;
		let arrayName = e.target.dataset.arrayname ?? e.currentTarget.dataset.arrayname;

		//console.log(name, value, idx, arrayName);
		if (name === "__REMOVE__") {
			this.setState(Update(this.state, { [arrayName]: { $splice: [[idx, 1]] } }));
		} else if (name) {
			this.setState(Update(this.state, { [arrayName]: { [idx]: { [name]: { $set: value } } } }));
		}

	}

	addRecpipt() {
		let receipts = [...this.state.receipts];
		receipts.push({
			receiptNo: "",
			details: "",
			amt: "0",
			ccy: "MMK",
			benefit: ""
		});
		this.setState({ receipts });
	}

	addAttending() {
		let attendings = [...this.state.attendings];
		attendings.push({
			diagnosis: "",
			dateSymptomsFirstAppeared: "",
			dateDiagnosedCondition: "",
			datePrevTreatment: "",
			drHospitalPrevTreatment: "",
			receipt: "",
		});
		this.setState({ attendings });
	}

	onDrop(files) {
		console.log("onDrop");
		var queuing = this.state.files;
		async.forEachLimit(files, 1, (f, cb) => {
			let accept = ["image/jpeg","image/bmp","image/png","image/tif","image/tiff","application/pdf"];
			//console.log("f.type : ",f.type, accept.indexOf(f.type) < 0)
			if(accept.indexOf(f.type) < 0){
				this.props.notify("invaild File Type");
				return;
			}
			//console.log("forEachLimit", f);
			if (!f.uploaded) {
				f.started = false;
				f.uploading = false;
				f.uploaded = false;
				f.progress = 0;
				f.materialToken = null;
			}

			if (!f.progress && f.type === 'image\/jpeg' && typeof FileReader === 'function') {
				var dataurl_reader = new FileReader();
				dataurl_reader.onloadend = function () {
					//var imgData = atob(this.result.replace(/^.*?,/, ''));
					/*  try {
						var jpeg = new $j(imgData, f.preview);
						f.meta = {
						  width: jpeg.metaGroups.general.pixelWidth.value,
						  height: jpeg.metaGroups.general.pixelHeight.value,
						  orientation: jpeg.metaGroups.tiff.Orientation.value,
						};
					  } catch(e) {}
					  */
					if (!f.meta) {
						var img = new Image();
						img.onload = function () {
							f.meta = { width: this.width, height: this.height, orientation: 1 };
							queuing.push(f);
							cb();
						};
						//let urlCreator = window.URL || window.webkitURL;
						//console.log("urlCreator.createObjectURL(imgData)", this.result);
						img.src = this.result;
						return;
					}
					queuing.push(f);
					cb();
				}
				dataurl_reader.readAsDataURL(f);
			}
			/*
			else if (!f.progress && f.type.indexOf("image") !== -1 && typeof Image === 'function') {
				var img = new Image();
				img.onload = function () {
					f.meta = { width: this.width, height: this.height, orientation: 1 };
					queuing.push(f);
					cb();
				};
				//let urlCreator = window.URL || window.webkitURL;
				//console.log("urlCreator", f);
				img.src = this.result;
			}
			*/
			else {
				queuing.push(f);
				cb();
			}



		}, (error) => {
			console.log("Ready to push");
			this.setState(Update(this.state, {
				files: { $set: queuing },
				//form: { error: { file: { $set: null }}},
			}));
			this.pushFiles();
		});
	}

	removeFile(filename) {
		console.log("filename", filename);
		let files = this.state.files.filter(f => f.filename !== filename);
		let attachments = this.state.attachments.filter(f => f.filename !== filename);
		this.setState({ files, attachments });
	}

	pushFiles() {
		console.log("pushFiles this.state.files", this.state.files);
		this.state.files.forEach((file) => {
			if (!file.started && !file.materialToken) {
				file.started = true;
				//this.pushFileViaGraphQL(file);
				this.uploadFile(file);
			}
		});
	}

	async uploadFile(file) {
		console.log("uploadFile");

		var fidx = this.state.files.indexOf(file);
		const config = { headers: { 'Content-Type': 'multipart/form-data' } };
		let fd = new FormData();

		fd.append('File[]', file);
		fd.append('trackNo', this.state.barcodeId);

		axios.post(`https://api.ulink.ins-link.com/cl-upload`, fd, config)
			.then((response) => {
				console.log(response);

				const { data, errors } = response ?? {}
				const placeClaimMaterial = data ?? { filename: "undefined", token: "--" };
				const error = errors?.[0]
				file.uploading = false
				file.uploaded = true
				file.progress = 1
				if (error) {
					file.error = error
					this.setState(Update(this.state, {
						files: { $splice: [[fidx, 1, file]] },
					}))
					return
				}
				file.filename = placeClaimMaterial.filename
				file.materialToken = placeClaimMaterial.token
				this.setState(Update(this.state, {
					files: { $splice: [[fidx, 1, file]] },
					attachments: {
						$push: [{
							filename: file.filename,
							materialToken: file.materialToken,
							docName: file.docName

						}]
					}
				}))

			})
			.catch(error => {
				console.log(error);
			})
	}

	async pushFileViaGraphQL(file) {
		console.log("pushFileViaGraphQL");
		var fidx = this.state.files.indexOf(file);
		await Gx.fetchGraphWithVars({
			file1: file,
		}, `mutation(
		  $file1: Upload!
		) {
		  placeClaimMaterial(
			materialFile: $file1 ${file.meta ? Gx.toQlParams(file.meta) : ""}
		  ) {
			filename token
		  }
		}`).then(json => {
			const { data, errors } = json ?? {}
			const placeClaimMaterial = data?.placeClaimMaterial
			const error = errors?.[0]
			file.uploading = false
			file.uploaded = true
			file.progress = 1
			if (error) {
				file.error = error
				this.setState(Update(this.state, {
					files: { $splice: [[fidx, 1, file]] },
				}))
				return
			}
			file.filename = placeClaimMaterial.filename
			file.materialToken = placeClaimMaterial.token
			this.setState(Update(this.state, {
				files: { $splice: [[fidx, 1, file]] },
				attachments: {
					$push: [{
						filename: file.filename,
						materialToken: file.materialToken,
						docName: file.docName

					}]
				}
			}))
		}).catch(err => {
			console.log(err)
			file.uploading = false
			file.uploaded = true
			file.error = err
			file.queued = false
			this.setState(Update(this.state, { files: { $splice: [[fidx, 1, file]] } }))
		})
	}

	async myCustomFileGetter(event, key) {
		console.log("myCustomFileGetter");
		const files = [];
		const fileList = event.dataTransfer ? event.dataTransfer.files : event.target.files;

		//console.log("myCustomFileGetter", key);
		for (var i = 0; i < fileList.length; i++) {
			const file = fileList.item(i);

			Object.defineProperty(file, 'docName', {
				value: key
			});

			files.push(file);
		}

		return files;
	}

	fileItem(uploadedFile, ufIdx) {
		//console.log("uploadedFile",uploadedFile);	
		return (<li key={ufIdx}
			className={"p-2 mb-2 fade-in " +
				(uploadedFile.error ? "rounded bg-danger text-white " : "rounded bg-success text-white ")
			}
		>
			<span className={"fa-li " + (uploadedFile.error ? "text-danger" : "text-success")}><i className="fas fa-file fa-2x"></i></span>
			<span className={"fa-li mt-5  text-danger cursor-pointer"}
				data-filename={uploadedFile.filename}
				onClick={e => {
					this.removeFile(e.target.dataset.filename ?? e.currentTarget.dataset.filename);
				}}
			><i className="fas fa-trash-alt fa-2x"></i></span>
			<div
				className={(uploadedFile.filename ? "cursor-pointer " : " ")}
				onClick={e => {
					if (uploadedFile.filename) {
						window.open(`${process.env.REACT_APP_API_URL_GRAPHQL_HOST}/claim/material/${uploadedFile.materialToken}/${uploadedFile.filename}`, "_blank")
					}
				}}
			>
				{uploadedFile.docName} : {uploadedFile.filename} {uploadedFile.size ? `- ${parseInt(uploadedFile.size / 1000)}kb` : ""}
				{uploadedFile.error && <div className="w-100 rounded p-2 bg-white text-danger fade-in">Error : {uploadedFile.error.message}</div>}
				{uploadedFile.materialToken && <div className="w-100 rounded p-2 bg-white text-success text-center fade-in">Upload Successful</div>}
				{uploadedFile.progress < 1 && <div className="w-100 rounded p-2 bg-white text-success text-center"><i className="fas fa-spinner fa-pulse fa-2x"></i></div>}
			</div>
		</li>)
	}

	dataURLtoBlob(dataURL) {
		let array, binary, i, len;
		binary = atob(dataURL.split(',')[1]);
		array = [];
		i = 0;
		len = binary.length;
		while (i < len) {
			array.push(binary.charCodeAt(i));
			i++;
		}
		return new Blob([new Uint8Array(array)], {
			type: 'image/png'
		});
	}

	async submitClaims() {

		store.set("claimDetail",{...this.state})

		let dateFrom = this.state.dateConsultation ?? this.state.dateAdmitted ?? this.state.dateAccident ?? this.state.effDate;
		let dateTo = this.state.dateConsultation ?? this.state.dateDischarged ?? this.state.dateAccident ?? this.state.expDate;
		console.log(dateFrom)

		if(!dayjs(dateFrom).isBetween(this.state.effDate, this.state.expDate)){
			this.props.notify(`Date should within Policy effective date (${dayjs(this.state.effDate).format("MM/DD/YYYY")} to ${dayjs(this.state.expDate).format("MM/DD/YYYY")})`);
			return;
		}


		if (!this.state.isAgreeStatment) {
			this.props.notify("You must agree to the Authority, Release, and Declaration Statements");
			return;
		}



		if(!dateFrom || !dateTo){
			this.props.notify("Please provide date that related to the claim");
			return;
		}

		if(
			this.state.phoneMobile === "" || 
			this.state.claimantEmail === "" || 
			this.state.contactPersonName === "" || 
			!this.state.dateAccident
		){
			this.props.notify("Please provide Basic Detail");
			return;
		}

		
		let errAttending = this.state.attendings.filter(l=>(l.diagnosis === null || l.diagnosis === "" ||
				l.dateSymptomsFirstAppeared === null || l.dateSymptomsFirstAppeared === "" ||
				l.dateDiagnosedCondition === null || l.dateDiagnosedCondition === "" ||
				l.receipt === null || l.receipt === ""
			));

		if (errAttending.length > 0) {
			this.props.notify("Attending Details Incomplete");
			return;
		}
	
		let errReceipt = this.state.receipts.filter(l=>(l.receiptNo === null || l.receiptNo === "" ||
				l.details === null || l.details === "" ||
				l.ccy === null || l.ccy === "" ||
				l.amt === null || l.amt === "" ||
				l.benefit === null || l.benefit === ""
			));

		if (errReceipt.length > 0) {
			this.props.notify("Receipt Details Incomplete");
			return;
		}
	

		this.props.isFetching();

		//Capture claim form and upload
		await html2canvas(document.querySelector("#claim-submission-form")).then(canvas => {

			const config = { headers: { 'Content-Type': 'multipart/form-data' } };
			let fd = new FormData();
			const file = this.dataURLtoBlob(canvas.toDataURL());
			fd.append('File[]', file, "claim-form-cap-scn.png");
			fd.append('trackNo', this.state.barcodeId);

			axios.post(`https://api.ulink.ins-link.com/cl-upload`, fd, config)



			//document.body.appendChild(canvas)
		});

		let claimData = { ...this.state };

		delete claimData.benHeadList;
		delete claimData.provList;
		delete claimData.sysCodeList;
		delete claimData.claimDocList;
		delete claimData.ICD10Cat;
		delete claimData.files;
		delete claimData.policies;
		delete claimData.productPlan;
		delete claimData.plans;
		//delete claimData.policy;
		//delete claimData.memberNo;
		delete claimData.action;
		delete claimData.claimId;
		delete claimData.demoProductPlan;

		claimData.attachments.forEach(att => {
			delete att.materialToken;
		});

	
		let ncClaims = await this.buildNCClaims(claimData);
		//console.log(JSON.stringify(ncClaims,null,2));
		//return ;

		
		axios.post(`https://api.ulink.ins-link.com/cl-submit`, {...claimData, lines: ncClaims?.lines ?? []}).then(data => {
				console.log(data);
			this.props.isFetching(false);
			if (true) {

				Swal.fire({
					title: 'Claims submited',
					html: "<div class='text-center'>Claims submited, your claim submission number is <h2>" +
						this.state.barcodeId
						+ "</h2>Tracking number: <br><b>"+
						this.state.trackNo
						+"</b>"
						+"<p><br/><br/>We thank you and acknowledge your claims submission.<br/>"
						+"We will inform you if additional information is required.<br/>"
						+"Please note that if all the necessary documents supporting your claim has been satisfactorily submitted,<br/>"
						+"we will notify you of the claim outcomes in 14-21 business days on average.<br/><br/>"
						+"IF you have any questions, kindly contact us at <a href='mailto:lifereclaim-mi@mminsurance.gov.mm'>lifereclaim-mi@mminsurance.gov.mm</a> .</p></div>",
					icon: 'success',
					width: "85%"
				});
				store.set("justSubmitedClaimBarcodeId", this.state.barcodeId);
				
			}
			//this.props.history.goBack();
			this.props.history.push("/");
		})
			.catch(error => {
				console.log(JSON.stringify(error, null, 0));
			});
		return {};
	}

	async buildNCClaims(claimData) {

		let diagOids = await this.getJsonFile("/data/DIAG_OID.json");
		//let behdOids = await this.getJsonFile("/data/BEHD_OID.json");

		//console.log("diagOids", diagOids);
		console.log("this.selectedMember", this.selectedMember);

		let ncClaimlines =
		{
			"lineNo": 0,
			"product": this.policyPlan?.productPlan?.productType?.productRefNo,
			"clType": "CL_TYPE_M",
			"membershipRefNo": this.selectedMember?.membershipRefNo,
			"memberNo": this.selectedMember?.membership?.memberNo,
			"policyPlanRefNo": this.policyPlan?.refNo,
			"benefitRefNo": "",
			"diagnosisRefNo": "",
			"countryTreatment": "COUNTRY_095",
			"procRefNo": null,
			"providerRefNo": null,
			"provName": '',
			"presCcy": "CCY_MMK",
			"orgPresAmt": "",
			"rcvDate": "",
			"symptomDate": "",
			"incurDateFrom": "",
			"incurDateTo": "",
			"tripDateFrom": null,
			"tripDateTo": null,
			"incidDate": null,
			"bedType": null,
			"er": false,
			"nos": null,
			"bankName": this.state.paymentBank ?? "",
			"bankBranch": this.state.bankBranchAccount ?? "",
			"acctName": this.state.bankName ?? "",
			"acctNo": this.state.bankAccountNo ?? "",
			"payAddr1": this.state.bankAccountAddress ?? "",
			"payRmk": ((this.state.bankAccountType ?? "") + " " + (this.state.bankSWIFT ?? "") + " " + (this.state.bankCcy ?? "")).trim()
		};

		let orgPresAmtIdx = {};
		let claimsLines = claimData.receipts.flatMap((rpt, rIdx, rAry) => {

			if (isNaN(rpt.amt) || rpt.amt.trim() === "") rpt.amt = 0;
			if (rpt.receiptNo === "") rpt.receiptNo = "NIL";

			if (!orgPresAmtIdx[rpt.receiptNo])
				orgPresAmtIdx[rpt.receiptNo] = [];

			let lines = [];

			if (rpt.benefit && rpt.benefit !== "") {
				let benHeads = rpt.benefit.split(",");
				let benefits = benHeads
					.map(bh => this.lmt2233
						.find(ben => ben.BEN_HEAD === bh));
				let diags = claimData.attendings.filter(att => att.receipt.split(",").includes(rpt.receiptNo));
				lines = benefits.flatMap(ben => {
					if (diags && diags.length > 0) {
						orgPresAmtIdx[rpt.receiptNo].push(rpt.amt);
						return diags.map(d => ({
							...ncClaimlines,
							amt: rpt.amt,
							ccy: rpt.ccy,
							invNo: rpt.receiptNo,
							benefitRefNo:  ben.BEHD_OID,
							benType: ben.SCMA_OID_BEN_TYPE,
							diagnosisRefNo: diagOids.find(doid => doid.DIAG_CODE === d.diagnosis).DIAG_OID,
							diagCode: d.diagnosis,
							diagDesc: this.state.ICD10Cat.find(doid => doid.code === d.diagnosis).desc,
							provName: d.drHospitalPrevTreatment,
							symptomDate: dayjs.utc(d.dateSymptomsFirstAppeared).startOf("day").format()
						}));
					} else {
						orgPresAmtIdx[rpt.receiptNo].push(rpt.amt);
						return [{
							...ncClaimlines,
							ccy: rpt.ccy,
							amt: rpt.amt,
							nvNo: rpt.receiptNo,
							benefitRefNo: ben.BEHD_OID
						}];
					}
				})
			} else {
				orgPresAmtIdx[rpt.receiptNo].push(rpt.amt);
				lines = [{ ...ncClaimlines, ccy: rpt.ccy, amt: rpt.amt, nvNo: rpt.receiptNo }];
			}

			return lines;
		})


		console.log(orgPresAmtIdx)
		claimsLines.forEach((cl, idx) => {

			if (orgPresAmtIdx[cl.receiptNo] && orgPresAmtIdx[cl.receiptNo].length > 0) {
				cl.orgPresAmt = "" + (parseFloat(orgPresAmtIdx[cl.receiptNo][1]) / orgPresAmtIdx[cl.receiptNo].length);
			} else {
				cl.orgPresAmt = "" + parseFloat(cl.amt);
			}
			delete cl.amt;

			cl["lineNo"] = idx + 1;
			cl["presCcy"] = "CCY_"+cl.ccy;
			delete cl.ccy;

			cl["rcvDate"] = dayjs.utc().startOf("day").format();
			cl["incurDateFrom"] = this.state.dateConsultation ?? this.state.dateAdmitted ?? this.state.dateAccident ?? this.state.effDate;
			cl["incurDateTo"] = this.state.dateConsultation ?? this.state.dateDischarged ?? this.state.dateAccident ?? this.state.expDate;
			cl["tripDateFrom"] = this.state.effDate
			cl["tripDateTo"] = this.state.expDate;
			cl["incidDate"] = this.state.dateAccident;
			//cl["procRefNo"] = this.state.externalRefNo;

		});

		//console.log("claimsLines",claimsLines);
		console.log(JSON.stringify(claimData, null, 2))
		console.log(JSON.stringify(claimsLines, null, 2))
		return { lines: claimsLines };
	}

	render() {
		let receiptsTotal = {};
		let t = l => this.lang[this.state.lang][l];
		return (
			<>
				<div className="content pb-0 mt-2 claim-submission-form" id="claim-submission-form">
					<fieldset disabled={this.state.action === "view"

					}>
						<Row className="px-0 pt-4">

							<Col md="12" className="bg-white p-3 mb-4">
								<Row className="fade-in">
									<Col xs="2">
										<img alt="Logo" src="/img/Ulink-LOGO.jpg"
											style={{ width: "96px" }}
										/>
									</Col>
									<Col xs="5" className="pt-4">
										<h3 className="align-middle">{t("UlinkAssist")}</h3>
									</Col>
									<Col xs="5" className="pt-4 text-right">
										<span
										className={"px-4 py-2 m-1 rounded cursor-pointer "+((this.state.lang === "en-US")?"bg-success":"bg-secondary")}
										onClick={e=>{this.setState({"lang":"en-US"})}}
										><img src="/img/enFlag.png" style={{width:"24px",height:"24px"}}/> English</span> 
										<span
										className={"px-4 py-2 m-1 rounded cursor-pointer "+((this.state.lang === "my-MR")?"bg-success":"bg-secondary")}
										onClick={e=>{this.setState({"lang":"my-MR"})}}
										><img src="/img/myFlag.png" style={{width:"24px",height:"24px"}}/>  မြန်မာ</span>
									</Col>
								</Row>
							</Col>

							<Col md="12" className="bg-white py-2">
								<Row className="rounded bg-light m-1 p-1 pt-4 mb-4 fade-in">
									<Col xs="12" md={"11"}>
										<Card body className="bg-warning text-dark p-2 text-center" style={{ marginTop: "-52px" }}>
											<b>{t("ImportantNotice")}</b>
										</Card>
									</Col>
									<div className="w-100" />

									<Col xs="12" className="mb-4">
										<ol>
											<li>{t("ImportantNotice-1")}</li>
											<li>{t("ImportantNotice-2")}</li>
											<li>{t("ImportantNotice-3")}</li>
											<li>{t("ImportantNotice-4")}</li>
										</ol>

									</Col>
								</Row>
							</Col>


							<Col md="12" className="bg-white py-2">
								{this.state.policies.length === 0 && <Row className="rounded bg-light m-1 p-1 pt-4 mb-4 fade-in">
									<Col xs="12" className="text-center p-4">
										<Spinner color="info" style={{ width: '3rem', height: '3rem' }} />
									</Col>
								</Row>}
								{false && this.state.policies.length === 0 && <Row className="rounded bg-light m-1 p-1 pt-4 mb-4">
									<Col xs="12" md={this.props.isMP ? "11" : "4"}>
										<Card body className="bg-warning text-dark p-2 text-center" style={{ marginTop: "-52px" }}>
											<b>PRODUCT</b>
										</Card>
									</Col>
									<div className="w-100" />

									<Col xs="12" className="mb-4">

										<Select
											name="demoProductPlan"
											isDisabled={this.state.action === "view"}
											onChange={(so) => {
												this.handleChange({
													target: {
														name: "demoProductPlan",
														value: so ? this.state.plans.find(p => p.id === so.value) : null,
													}
												})
											}}
											options={this.state.plans.map(p => (
												{
													label: `${p.planId} - ${p.name}`,
													value: p.id
												}
											))}
											value={this.state.demoProductPlan ?
												[this.state.plans.find(p => p.id === this.state.demoProductPlan?.id)].map(p => (
													{
														label: `${p.planId} - ${p.name}`,
														value: p.id
													})) : ""}
											className="basic-multi-select"
											classNamePrefix="select"
										/>
									</Col>
								</Row>}
							</Col>
							<Col md="6" className="bg-white py-2">
								{this.state.policies.length > 0 && <Row className="rounded bg-light m-1 p-1 pt-4 mb-4 fade-in">
									<Col xs="12" md={this.props.isMP ? "11" : "4"}>
										<Card body className="bg-warning text-dark p-2 text-center" style={{ marginTop: "-52px" }}>
											<b>{t("POLICY")}</b>
										</Card>
									</Col>
									<div className="w-100" />

									<Col xs="12" className="mb-4">

										<Select
											name="policy"
											isDisabled={this.state.action === "view"}
											onChange={(so) => {
												this.handleChange({
													target: {
														name: "policy",
														value: so ? so.value : "",
													}
												})
											}}
											options={this.state.policies.map(p => (
												{
													label: `${p.externalRefNo} - ${p.desc} `,
													value: p.refNo
												}
											))}
											value={this.state.policy ?
												[this.state.policies.find(p => p.refNo === this.state.policy)].map(p => (
													{
														label: p.externalRefNo, value: p.refNo
													})) : ""}
											className="basic-multi-select"
											classNamePrefix="select"
										/>
									</Col>
								</Row>}
							</Col>



							<Col md="6" className="bg-white py-2">
								{this.state.policy && this.policyPlan && <Row className="rounded bg-light m-1 p-1 pt-4 mb-4 fade-in">
									<Col xs="12" md={this.props.isMP ? "11" : "4"}>
										<Card body className="bg-warning text-dark p-2 text-center" style={{ marginTop: "-52px" }}>
											<b>{t("CLAIMANT")}</b>
										</Card>
									</Col>
									<div className="w-100" />

									<Col xs="12" className="mb-4">

										<Select
											name="memberNo"
											isDisabled={this.state.action === "view"}
											onChange={(so) => {
												this.handleChange({
													target: {
														name: "memberNo",
														value: so ? so.value : "",
													}
												})
											}}
											options={this.policyPlan.memberPlans.map(m => (
												{
													label: `${m.memberNo} - ${m.mbrFirstName} ${m.mbrLastName}`,
													value: m.memberNo
												}
											))}
											value={this.state.memberNo ?
												[this.policyPlan.memberPlans.find(m => m.memberNo === this.state.memberNo)].map(m => (
													{
														label: `${m.memberNo} - ${m.mbrFirstName} ${m.mbrLastName}`,
														value: m.memberNo
													})) : ""}
											className="basic-multi-select"
											classNamePrefix="select"
										/>
									</Col>
								</Row>}
							</Col>
							<fieldset disabled={!(this.state.memberNo || this.state.demoProductPlan)} style={{ opacity: !(this.state.memberNo || this.state.demoProductPlan) ? "0.5" : "1", transition: "all 300ms ease 0s" }}>
								<Col md="12" className="bg-white py-2">
									<Row className="rounded bg-light m-1 p-1 pt-4 mb-4">
										<Col xs="12" md={this.props.isMP ? "11" : "4"}>
											<Card body className="bg-warning text-dark p-2 text-center" style={{ marginTop: "-52px" }}>
												<b>{t("BasicDetails")}</b>
											</Card>
										</Col>

										<Col xs="12" className="mb-4">
											<Label>{t("DateofAccidentOccurrence")} </Label>
											<DatePicker
												className="form-control"
												showYearDropdown
												showMonthDropdown
												minDate={new Date(1900, 1, 1)}
												onChange={(d) => { this.setDates(d, "dateAccident") }}
												selected={this.state.dateAccident ? dayjs.utc(this.state.dateAccident).toDate() : undefined}
											/>
										</Col>

										<Col xs="12" className="mb-4">
											<Label>{t("ContactPersonName")}</Label>
											<Input type="text"
												className="bg-white"
												placeholder="Name"
												name="contactPersonName"
												onChange={this.handleChange}
												value={this.state.contactPersonName ?? ""}
											/>
										</Col>
										<Col xs="12" className="mb-4">
											<Label>{t("Email")}</Label>
											<Input type="text"
												className="bg-white"
												placeholder="E-mail"
												name="claimantEmail"
												onChange={this.handleChange}
												value={this.state.claimantEmail ?? ""}
											/>
										</Col>
										<Col xs="12" className="mb-4">
											<Label>{t("PreferredTelephoneNumber")}</Label>
											<Input type="text"
												className="bg-white"
												placeholder=""
												name="phoneMobile"
												onChange={this.handleChange}
												value={this.state.phoneMobile ?? ""}
											/>
										</Col>
									</Row>
									{/**** User requeted to hidden this part; 20220321 ; Jira TPS-7 ; ****/}
									<Row className="rounded bg-light mx-1 p-1 pt-4 my-5">
										<Col xs="12" md={this.props.isMP ? "11" : "4"}>
											<Card body className="bg-warning text-dark p-2 text-center" style={{ marginTop: "-52px" }}>
												<b>{t("InsuredDetails")}</b>
												{/**Policyholder? */}
											</Card>
										</Col>
										<div className="w-100" />
										<Col xs="12" className="mb-4">
											{/**policyType */}
											<CustomInput type="radio" id={"policyType-I"} name="policyType"
												label={t("Individual")} value={"I"}
												checked={this.state.policyType === "I"}
												onChange={this.handleChange}
												inline
											/>

											<CustomInput type="radio" id={"policyType-E"} name="policyType"
												label={t("Company")} value={"E"}
												checked={this.state.policyType === "E"}
												onChange={this.handleChange}
												inline
											/>
										</Col>
										<Col xs="12" className="mb-4">
											<Label>{t("Name")}</Label>
											<Input type="text"
												className="bg-white"
												placeholder="Name"
												name="firstName"
												onChange={this.handleChange}
												value={this.state.firstName ?? ""}
											/>
										</Col>
										<Col xs="12" md="6" className="mb-4">
											<Label>{t("PassportNumberID")}</Label>
											<Input type="text"
												className="bg-white"
												placeholder="Passport Number/ID"
												name="idnoInsured"
												onChange={this.handleChange}
												value={this.state.idnoInsured ?? ""}
											/>
										</Col>
										<Col xs="12" md="6" className="mb-4">
											<Label>{t("NationalityInsured")}</Label>
											<Input type="text"
												className="bg-white"
												placeholder="Nationality (Insured)"
												name="nationalityInsured"
												onChange={this.handleChange}
												value={this.state.nationalityInsured ?? ""}
											/>
										</Col>
										<Col xs="12" md="6" className="mb-4">
											<Label>{t("DateofBirth")}</Label>
											<DatePicker
												className="form-control"
												showYearDropdown
												showMonthDropdown
												minDate={new Date(1900, 1, 1)}
												onChange={(d) => { this.setDates(d, "dobInsured") }}
												selected={this.state.dobInsured ? dayjs.utc(this.state.dobInsured).toDate() : undefined}
											/>
										</Col>
									</Row>
									<Row className="rounded bg-light mx-1 p-1 pt-4 my-5">
										<Col xs="12" md={this.props.isMP ? "11" : "4"}>
											<Card body className="bg-warning text-dark p-2 text-center" style={{ marginTop: "-52px" }}>
												<b>{t("ClaimantDetails")}</b>
												{/**Policyholder? */}
											</Card>
										</Col>
										<div className="w-100" />
										<Col xs="12" className="mb-4">
											<Label>{t("SameAsInsured")}</Label><br />
											<CustomInput type="radio" id={"isSameIns-Y"} name="isSameIns"
												label={t("Yes")} value={"Y"}
												checked={this.state.isSameIns === "Y"}
												onChange={this.handleChange}
												inline
											/>

											<CustomInput type="radio" id={"isSameIns-N"} name="isSameIns"
												label={t("No")} value={"N"}
												checked={this.state.isSameIns === "N"}
												onChange={this.handleChange}
												inline
											/>
										</Col>
										{(this.state.isSameIns === "N") && <>
											<Col xs="12" className="mb-4">
												<Label>{t("Name")}</Label>
												<Input type="text"
													className="bg-white"
													placeholder="Name"
													name="claimantName"
													onChange={this.handleChange}
													value={this.state.claimantName ?? ""}
												/>
											</Col>
											<Col xs="12" md="6" className="mb-4">
												<Label>{t("PassportNumberID")}</Label>
												<Input type="text"
													className="bg-white"
													placeholder="Passport Number/ID"
													name="idno"
													onChange={this.handleChange}
													value={this.state.idno ?? ""}
												/>
											</Col>
											<Col xs="12" md="6" className="mb-4">
												<Label>{t("NationalityClaimant")}</Label>
												<Input type="text"
													className="bg-white"
													placeholder="Nationality (Claimant)"
													name="nationality"
													onChange={this.handleChange}
													value={this.state.nationality ?? ""}
												/>
											</Col>
											<Col xs="12" md="6" className="mb-4">
												<Label>{t("DateofBirth")}</Label>
												<DatePicker
													className="form-control"
													showYearDropdown
													showMonthDropdown
													minDate={new Date(1900, 1, 1)}
													onChange={(d) => { this.setDates(d, "dob") }}
													selected={this.state.dob ? dayjs.utc(this.state.dob).toDate() : undefined}
												/>
											</Col>
											<Col xs="12" md="6" className="mb-4">
												<Label>{t("RelationshipWithInsured")}</Label>
												<Input type="text"
													className="bg-white"
													placeholder="Relationship with Insured"
													name="relWithInsured"
													onChange={this.handleChange}
													value={this.state.relWithInsured ?? ""}
												/>
											</Col>
										</>}
									</Row>


									<Row className="rounded bg-light mx-1 p-1 py-4 mt-5 mb-2">
										<Col xs="12" md={this.props.isMP ? "11" : "4"}>
											<Card body className="bg-warning text-dark p-2 text-center" style={{ marginTop: "-52px" }}>
												<b>{t("ModeOfPayment")}</b>
											</Card>
										</Col>
										<div className="w-100" />




										<Col xs="12" className="mb-4">
											<Label>{t("BankName")}</Label>
											<Input type="text"
												className="bg-white"
												placeholder=""
												name="paymentBank"
												value={this.state.paymentBank ?? ""}
												onChange={this.handleChange}
											/>
										</Col>
										<Col xs="12" className="mb-4">
											<Label>{t("BranchName")}</Label>
											<Input type="text"
												className="bg-white"
												placeholder=""
												name="bankBranchAccount"
												value={this.state.bankBranchAccount ?? ""}
												onChange={this.handleChange}
											/>
										</Col>
										<Col xs="12" className="mb-4">
											<Label>{t("AccountHolderName")}</Label>
											<Input type="text"
												className="bg-white"
												placeholder=""
												name="bankName"
												value={this.state.bankName ?? ""}
												onChange={this.handleChange}
											/>
										</Col>

										<Col xs="12" className="mb-4">
											<Label>{t("AccountNumber")}</Label>
											<Input type="text"
												className="bg-white"
												placeholder=""
												name="bankAccountNo"
												value={this.state.bankAccountNo ?? ""}
												onChange={this.handleChange}
											/>
										</Col>


										<Col xs="12" className="mb-4">
											<Label>{t("Remark")}</Label>
											<Input type="text"
												className="bg-white"
												placeholder=""
												name="bankRemark"
												value={this.state.bankRemark ?? ""}
												onChange={this.handleChange}
											/>
										</Col>
									</Row>

									<Row className="rounded bg-light mx-1 p-1 py-4 my-5">
										<Col xs="12" md={this.props.isMP ? "11" : "4"}>
											<Card body className="bg-warning text-dark p-2 text-center" style={{ marginTop: "-52px" }}>
												<b>{t("OFFICIAL_RECEIPTS_SUBMITTED")}</b>
											</Card>
										</Col>
										<div className="w-100" />
										<Col xs="12">
											{this.state.receipts.map((receipt, idx) => {
												if (!receiptsTotal[receipt.ccy])
													receiptsTotal[receipt.ccy] = 0;

												receiptsTotal[receipt.ccy] += isNaN(receipt.amt ?? 0) ? 0 : parseFloat(receipt.amt ?? 0);
												return (
													<Row className="mb-2 rounded bg-white" key={idx}>
														<Col xs="12" md={this.props.isMP ? "6" : "3"}>
															<Label>{t("OfficialReceiptNumber")}</Label>
															<Input type="text"
																className="bg-white"
																placeholder=""
																name="receiptNo"
																data-idx={idx}
																data-arrayname="receipts"
																onChange={this.handleArrayChange}
																value={receipt.receiptNo ?? ""}
															/>
														</Col>
														<Col xs="12" md="5">
															<Label>{t("DetailsOfPayment")}</Label>
															<Input type="text"
																className="bg-white"
																placeholder="professional fees, medicines, baggage, funeral and burial expenses, etc."
																title="professional fees, medicines, baggage, funeral and burial expenses, etc."
																name="details"
																data-idx={idx}
																data-arrayname="receipts"
																onChange={this.handleArrayChange}
																value={receipt.details ?? ""}
															/>
														</Col>
														{this.props.isMP && <div className="w-100"></div>}
														<Col xs="12" md={this.props.isMP ? "8" : "2"}>
															<Label>{t("Amount")}</Label>
															<Input type="text"
																className="bg-white"
																placeholder=""
																name="amt"
																data-idx={idx}
																data-arrayname="receipts"
																onChange={this.handleArrayChange}
																value={receipt.amt ?? ""}
															/>
														</Col>
														<Col xs="12" md={this.props.isMP ? "3" : "1"} className={this.props.isMP ? "" : "pr-0"}>
														<Label>{t("Currency")}</Label>
															<Input type="select"
																className="bg-white"
																placeholder=""
																name="ccy"
																data-idx={idx}
																data-arrayname="receipts"
																onChange={this.handleArrayChange}
																value={receipt.ccy ?? ""}
																style={{ height: "32px" }}
															>
																<option value=""></option>
																<option value="MMK">MMK</option>
																<option value="USD">USD</option>
															</Input>
														</Col>
														{this.state.action !== "view" && <Col xs="12" md="1" className="pt-4">
															<div
																className="cursor-pointer"
																style={{ fontSize: "16px" }}
																onClick={this.handleArrayChange}
																data-name="__REMOVE__"
																value="__REMOVE__"
																data-idx={idx}
																data-arrayname="receipts"
															>
																<i className="fas fa-times-circle"></i>
															</div>
														</Col>}
														<div className="w-100"></div>

														<Col xs="11" className="pr-0 py-2">
															<label>{t("RelatedBenefit")}</label>
															<Select
																isMulti
																name="benefit"
																data-idx={idx}
																data-arrayname="receipts"
																closeMenuOnSelect={false}
																onChange={(so) => {
																	this.handleArrayChange({
																		target: {
																			name: "benefit",
																			value: so ? so.map(s => s.value).join() : "",
																			dataset: {
																				idx: idx,
																				arrayname: "receipts"
																			}
																		}
																	})
																}}
																options={this.lmt2233
																	.map(bh => ({
																		label: bh.CODE_DESC, value: bh.BEN_HEAD
																	}))}
																value={receipt.benefit && receipt.benefit !== "" ? this.lmt2233
																	.filter(bh => (receipt.benefit.split(",").findIndex(rbh => rbh === bh.BEN_HEAD) >= 0))
																	.map(bh => ({
																		label: bh.CODE_DESC, value: bh.BEN_HEAD
																	})) : ""}
																isDisabled={this.state.action === "view"}
																className="basic-multi-select"
																classNamePrefix="select"
															/>
															
														</Col>

													</Row>
												)
											})}
											{this.state.action !== "view" && <Row>
												<Col xs="12" className="p-3">
													<div
														className="cursor-pointer w-100 rounded bg-white text-center"
														style={{ fontSize: "32px" }}
														onClick={this.addRecpipt}>
														{t("AddMoreReceipt")} <i className="fas fa-plus-circle"></i>
													</div>
												</Col>
											</Row>}
											{Object.keys(receiptsTotal).map((ccy, tidx) => (
												<Row key={tidx}>
													<Col xs="12" md={this.props.isMP ? "5" : "9"} className="text-right">
														<b>{t("TOTAL")}</b>
													</Col>
													<Col xs="12" md={this.props.isMP ? "4" : "2"}>
														<Input type="text"
															className="bg-white"
															placeholder=""
															value={isNaN(receiptsTotal[ccy]) ? 0 : receiptsTotal[ccy]}
															readOnly
														/>
													</Col>
													<Col xs="12" md={this.props.isMP ? "3" : "1"}>
														{ccy}
													</Col>
												</Row>
											))}
										</Col>

									</Row>


									<Row className="rounded bg-light mx-1 p-1 py-4 mt-5 mb-2">
										<Col xs="12" md={this.props.isMP ? "11" : "4"}>
											<Card body className="bg-warning text-dark p-2 text-center" style={{ marginTop: "-52px" }}>
												<b>{t("TypesOfClaims")}</b>
											</Card>
										</Col>
										<div className="w-100" />
										<Col xs="12" className="mb-4">
											<Label>{t("ClaimType")}</Label>
											<Input type="select"
												className="bg-white"
												placeholder=""
												name="claimType"
												value={this.state.claimType ?? ""}
												onChange={this.handleChange}
											>
												<option value="">Select...</option>
												<option value="Inpatient admission">{t("InpatientAdmission")}</option>
												<option value="Medical Expenses for Covid 19">{t("Covid19")}</option>
												<option value="Quarantine Benefit">{t("QuarantineBenefit")}</option>
												<option value="Personal Accident">{t("PersonalAccident")}</option>
												<option value="Permanent Disability">{t("PermanentDisability")}</option>
												<option value="Death">{t("Death")}</option>
											</Input>
										</Col>
										<Col xs="12" className="mb-4">
											<Label>{t("DidOverseas")}</Label><br />
											<CustomInput type="radio" id={"isOSEmergency-Y"} name="isOSEmergency"
												label={t("Yes2")} value={"Y"}
												checked={this.state.isOSEmergency === "Y"}
												onChange={this.handleChange}
												inline
											/>

											<CustomInput type="radio" id={"isOSEmergency-N"} name="isOSEmergency"
												label={t("No2")} value={"N"}
												checked={this.state.isOSEmergency === "N"}
												onChange={this.handleChange}
												inline
											/>

										</Col>
										<Col xs="12" className="mb-4">
											<Label>{t("DateAdmitted")}</Label>
											<DatePicker
												className="form-control"
												showYearDropdown
												showMonthDropdown
												minDate={new Date(1900, 1, 1)}
												onChange={(d) => { this.setDates(d, "dateAdmitted") }}
												selected={this.state.dateAdmitted ? dayjs.utc(this.state.dateAdmitted).toDate() : undefined}
											/>
										</Col>
										<Col xs="12">
										<Label>{t("DateDischarged")}</Label>
											<DatePicker
												className="form-control"
												showYearDropdown
												showMonthDropdown
												minDate={new Date(1900, 1, 1)}
												onChange={(d) => { this.setDates(d, "dateDischarged") }}
												selected={this.state.dateDischarged ? dayjs.utc(this.state.dateDischarged).toDate() : undefined}
											/>
										</Col>
										<div className="w-100" />

										<Col xs="12" className="bg-white rounded my-4 p-2">

											{this.state.attendings.map((attending, idx) => (
												<Row className="mb-2 rounded bg-light mx-0 p-2" key={idx}>
													{idx > 0 && this.props.isMP && <Col xs="12" className="bg-light mr-1">&nbsp;</Col>}
													<Col xs="12" md={this.props.isMP ? "11" : "2"}>
														<Label>{t("Diagnosis")}</Label>
														<Select
															name="diagnosis"
															data-idx={idx}
															data-arrayname="attendings"
															isDisabled={this.state.action === "view"}
															onChange={(so) => {
																this.handleArrayChange({
																	target: {
																		name: "diagnosis",
																		value: so ? so.value : "",
																		dataset: {
																			idx: idx,
																			arrayname: "attendings"
																		}
																	}
																})
															}}
															options={this.state.ICD10Cat
																.map(code => ({
																	label: `${code.code} - ${code.desc}`, value: code.code
																}))}
															value={attending.diagnosis && attending.diagnosis !== "" ? [this.state.ICD10Cat
																.find(code => code.code === attending.diagnosis)]
																.map(code => ({
																	label: `${code.code} - ${code.desc}`, value: code.code
																})) : ""}
															className="basic-multi-select"
															classNamePrefix="select"
														/>
													</Col>
													{this.state.action !== "view" && this.props.isMP && <Col xs="1" >
														<div
															className={"cursor-pointer pt-4"}
															style={{ fontSize: "16px" }}
															onClick={this.handleArrayChange}
															data-name="__REMOVE__"
															value="__REMOVE__"
															data-idx={idx}
															data-arrayname="attendings"
														>
															<i className="fas fa-times-circle"></i>
														</div>
													</Col>}
													<Col xs="11" className="pr-0 py-2">
														<label>{t("RelatedReceiptNo")}</label>
														<Select
															isMulti
															name="receipt"
															data-idx={idx}
															data-arrayname="attendings"
															closeMenuOnSelect={false}
															onChange={(so) => {
																this.handleArrayChange({
																	target: {
																		name: "receipt",
																		value: so ? so.map(s => s.value).join() : "",
																		dataset: {
																			idx: idx,
																			arrayname: "attendings"
																		}
																	}
																})
															}}
															options={this.state.receipts.map(rp => ({
																label: `${rp.receiptNo} - ${rp.details} : ${rp.amt} ${rp.ccy}`,
																value: rp.receiptNo
															}))}
															value={attending.receipt ? this.state.receipts
																.filter(rp => (attending.receipt.split(",").includes(rp.receiptNo)))
																.map(rp => ({
																	label: `${rp.receiptNo} - ${rp.details} : ${rp.amt} ${rp.ccy}`,
																	value: rp.receiptNo
																})) : ""}
															isDisabled={this.state.action === "view"}
															className="basic-multi-select"
															classNamePrefix="select"
														/>
													</Col>

													<Col xs="12" md={this.props.isMP ? "12" : "2"} className="pr-0 py-2">
														{t("similarConditionBefore")}
													</Col>
													<Col xs="12" md={this.props.isMP ? "12" : "2"} className="pr-0 py-2">
														{this.props.isMP && <Label>{t("SymptomDate")}</Label>}
														<DatePicker
															className="form-control"
															showYearDropdown
															showMonthDropdown
															minDate={new Date(1900, 1, 1)}
															onChange={(d) => {
																if (d) this.handleArrayChange({
																	target: {
																		name: "dateSymptomsFirstAppeared",
																		value: d ? dayjs.utc(dayjs(d).format("YYYY-MM-DD")).startOf('day').format() : undefined,
																		dataset: {
																			idx: idx,
																			arrayname: "attendings"
																		}
																	}
																})
															}}
															selected={attending.dateSymptomsFirstAppeared ? dayjs.utc(attending.dateSymptomsFirstAppeared).toDate() : undefined}
														/>
													</Col>

													<Col xs="12" md={this.props.isMP ? "12" : "2"} className="pr-0 py-2">
														{this.props.isMP && <Label>{t("DiagnosedDate")}</Label>}
														<DatePicker
															className="form-control"
															showYearDropdown
															showMonthDropdown
															minDate={new Date(1900, 1, 1)}
															onChange={(d) => {
																if (d) this.handleArrayChange({
																	target: {
																		name: "dateDiagnosedCondition",
																		value: d ? dayjs.utc(dayjs(d).format("YYYY-MM-DD")).startOf('day').format() : undefined,
																		dataset: {
																			idx: idx,
																			arrayname: "attendings"
																		}
																	}
																})
															}}
															selected={attending.dateDiagnosedCondition ? dayjs.utc(attending.dateDiagnosedCondition).toDate() : undefined}
														/>

													</Col>
													<Col xs="12" md={this.props.isMP ? "12" : "2"} className="pr-0 py-2">
														{this.props.isMP && <Label>{t("PrevTreatmentDate")}</Label>}
														<DatePicker
															className="form-control"
															showYearDropdown
															showMonthDropdown
															minDate={new Date(1900, 1, 1)}
															onChange={(d) => {
																if (d) this.handleArrayChange({
																	target: {
																		name: "datePrevTreatment",
																		value: d ? dayjs.utc(dayjs(d).format("YYYY-MM-DD")).startOf('day').format() : undefined,
																		dataset: {
																			idx: idx,
																			arrayname: "attendings"
																		}
																	}
																})
															}}
															selected={attending.datePrevTreatment ? dayjs.utc(attending.datePrevTreatment).toDate() : undefined}
														/>
													</Col>

													{this.state.action !== "view" && !this.props.isMP && <Col xs="12" md="1">
														<div
															className={"cursor-pointer " + (this.props.isMP ? "mt-5" : "")}
															style={{ fontSize: "16px" }}
															onClick={this.handleArrayChange}
															data-name="__REMOVE__"
															value="__REMOVE__"
															data-idx={idx}
															data-arrayname="attendings"
														>
															<i className="fas fa-times-circle"></i>
														</div>
													</Col>}

												</Row>
											))}
											{this.state.action !== "view" && <Row>
												<Col xs="12" className="p-3">
													<div
														className="cursor-pointer w-100 rounded bg-light text-center"
														style={{ fontSize: "32px" }}
														onClick={this.addAttending}>
														{t("AddMoreAttending")} <i className="fas fa-plus-circle"></i>
													</div>
												</Col>
											</Row>}
										</Col>

										<Col xs="12" md="6" className="mb-4">
											<Label>{t("DateOfAccident")}</Label>
											<DatePicker
												className="form-control"
												showYearDropdown
												showMonthDropdown
												minDate={new Date(1900, 1, 1)}
												onChange={(d) => { this.setDates(d, "dateAccident") }}
												selected={this.state.dateAccident ? dayjs.utc(this.state.dateAccident).toDate() : undefined}
											/>
										</Col>

										<Col xs="12" md="6" className="mb-4">
											<Label>{t("TimeOfAccident")}</Label>
											<Input type="text"
												className="bg-white"
												placeholder=""
												name="timeAccident"
												onChange={this.handleChange}
												value={this.state.timeAccident ?? ""}
											/>
										</Col>
										<Col xs="12" className="mb-4">
											<Label>{t("HowAccidentHappened")}</Label>
											<Input type="textarea"
												className="bg-white"
												placeholder=""
												name="locationAccident"
												onChange={this.handleChange}
												value={this.state.locationAccident ?? ""}
											/>
										</Col>


									</Row>


									<Row className="rounded bg-light mx-1 p-1 pt-4 my-5 ">
										<Col xs="12" md={this.props.isMP ? "11" : "6"}>
											<Card body className="bg-warning text-dark p-2 text-center" style={{ marginTop: "-52px" }}>
												<b>AUTHORITY, RELEASE, and DECLARATION STATEMENTS</b>
											</Card>
										</Col>
										<div className="w-100" />
										<Col xs="12">
											<p className="text-justify"><b>Authority: </b>I hereby authorize {this.companyName} Insurance, Inc. and all persons duly authorized and acting on their behalf
												to request and receive any information or document and record from any office or entity including but not limited to
												airlines/carrier, travel agencies, hotels, hospital, clinic, laboratory, attending physician and other health service
												provider, which information or document relates to any travel and accommodation papers and other related
												documents, medical history, examination, laboratory test results and/or treatment in connection with this claim, and
												such other matters related thereto. A photocopy of this is considered an original for all intents and purposes.
											</p>
											<p className="text-justify">
												<b>Release & Subrogation: </b>Any payment made by {this.companyName} or any payment received by me shall constitute as full, final
												and complete settlement of this claim. I further agree that the Company is subrogated to my rights of recovery on all
												claims and rights of action to the extent of the payments made and/or on account of the losses incurred or which may
												be incurred by the Company against any person, corporation or entity in connection with this claim and I further
												agree to authorize the Company to commence all legal actions and proceedings necessary to enforce my claim or
												recovery thereof with any undertaking to extend my cooperation or assistance whenever necessary.
											</p>
											<p className="text-justify">
												<b>Fraud Warning: </b>It is understood that Section 251 of the Insurance Code, as amended, imposes a fine not exceeding
												twice the amount claimed and/or imprisonment of two (2) years, or both, at the discretion of the court, to any person
												who presents or causes to be presented any fraudulent claim for the payment of a loss under a contract of insurance,
												and who fraudulently prepares, makes or subscribes any writing with intent to present or use the same, or to allow it
												to be presented in support of any claim.
											</p>
											<p className="text-justify">
												<b>Data Privacy Consent: </b>I understand that {this.companyName} collects and uses my personal data to service and administer
												my insurance policy, to provide appropriate and timely Medical and Travel Services, and for the purposes provided in
												the {this.companyName} Privacy Statement (available at -- ). By signing this form, I acknowledge that I
												have read and agree to the terms of the Privacy Statement, and understand that my data may be collected, shared,
												disclosed, transferred, used or otherwise processed by {this.companyName} in accordance with the Data Privacy Act of 2012,
												its implementing rules and regulations, and the Privacy Statement. Nothing in this form is intended to revoke or
												supersede any prior consent that I have given to {this.companyName} in respect of the processing activities involving my
												personal data.
											</p>
											<p className="text-justify">
												<b>Declaration: </b>I declare that all particulars stated on all pages of this form are complete and true, whether written by
												me or by anyone else on my behalf, shall be binding on me, and that the amounts being claimed herein are lawfully
												due to me under the terms and conditions of the Insurance Policy.
											</p>

										</Col>
										<Col xs="12" className="mb-4">
											<Label>{t("NameOfClaimant")}
												<span className="text-muted">{t("NameOfClaimantDesc")}</span></Label>
											<Input type="text"
												className="bg-white"
												placeholder=""
												name="claimantName"
												onChange={this.handleChange}
												value={this.state.claimantName ?? ""}
											/>
										</Col>
										<Col xs="12" className="mb-4">
											<CustomInput type="checkbox"
												id="isAgreeStatment"
												name="isAgreeStatment"
												label={"I agree with the above statements."}
												onChange={this.handleChange}
												checked={this.state.isAgreeStatment ?? false}
											/>
											<span className="text-muted"><b>{t("Note")}: </b>{t("NoteDesc")}</span>
										</Col>
									</Row>
									<Row className="rounded bg-light mx-1 p-1 py-4 mt-4 mb-5">


										<Col xs="12">
											<div className="">
												{this.state.claimDocList
													.filter((bh, idx) => {
														let display = false;
														if (idx === 0) return true;
														for (let i = 0; i < bh?.benHeads?.length ?? 0; i++) {
															//console.log(bh.benHeads[i], "vs", this.policyPlanBenHead);
															if (this.policyPlanBenHead?.includes(bh?.benHeads[i])) {
																display = true;
																break;
															}
														}
														return display;
													})
													.map((bh, idx, ary) => {
														let typeId = bh.docId;
														return (
															<React.Fragment key={idx} >
																<Card className="bg-light border-0"
																	style={{ boxShadow: "none" }}
																>
																	<Row>
																		<Col xs="12" className="">
																			<CustomInput type="checkbox"
																				id={typeId}
																				name={typeId}
																				label={bh.name}
																				onChange={this.handleBenHeadChange}
																				checked={this.state.claimBenHead.includes(typeId)}
																				className={typeId === "BASICCLAIMSREQUIREMENTS" ? "required-field" : ""}
																			/>
																		</Col>
																		{this.state.claimBenHead.includes(typeId) && <Col>
																			{bh.docs.map((doc, docIdx) => (
																				<Dropzone key={docIdx} onDrop={this.onDrop}
																				
																					getFilesFromEvent={e => this.myCustomFileGetter(e, `${bh.name} - ${doc}`)}
																				>
																					{({ getRootProps, getInputProps }) => (
																						<section className="p-1 mb-2 rounded bg-white container">
																							<div {...getRootProps({
																								style: {
																									flex: 1,
																									display: 'flex',
																									flexDirection: 'column',
																									alignItems: 'center',
																									padding: '8px',
																									borderWidth: 2,
																									borderRadius: 2,
																									borderColor: '#eeeeee',
																									borderStyle: 'dashed',
																									backgroundColor: '#FFFFFF',
																									color: '#0006',
																									outline: 'none',
																									transition: 'border .24s ease-in-out',
																									marginBottom: "9px"
																								},
																								test: typeId
																							})}>
																								<input {...getInputProps()} />
																								<b className="text-center"
																									style={{ fontSize: "14px", color: "#000F" }}>{doc}</b>
																								<br />
																								<p>Drag 'n' drop some files here, or click to select files<br/>
																								(Only *.pdf, *.tif, *.bmp, *.jpg and *.png images will be accepted)
																								</p>
																							</div>
																							<aside>
																								<ul className="fa-ul">
																									{this.state.files.filter(f => f.docName && f.docName === `${bh.name} - ${doc}`).map((uploadedFile, ufIdx) => (
																										this.fileItem(uploadedFile, ufIdx)
																									))}
																								</ul>
																							</aside>
																						</section>
																					)}
																				</Dropzone>
																			))}
																			{bh.subDocs && bh.subDocs.map((subDoc, subDocIdx) => (
																				<div key={subDocIdx} className="pl-4">
																					<Col>
																						<div className="bg-info text-white rounded p-2" style={{ fontSize: "18px" }}>{subDoc.name}</div>
																					</Col>
																					{subDoc.docs.map((doc, docIdx) => (

																						<Dropzone key={docIdx} onDrop={this.onDrop}

																							getFilesFromEvent={e => this.myCustomFileGetter(e, `${bh.name} - ${subDoc.name} - ${doc}`)}
																						>
																							{({ getRootProps, getInputProps }) => (
																								<section className="p-1 mb-2 rounded bg-white container">
																									<div {...getRootProps({
																										style: {
																											flex: 1,
																											display: 'flex',
																											flexDirection: 'column',
																											alignItems: 'center',
																											padding: '8px',
																											borderWidth: 2,
																											borderRadius: 2,
																											borderColor: '#eeeeee',
																											borderStyle: 'dashed',
																											backgroundColor: '#FFFFFF',
																											color: '#0006',
																											outline: 'none',
																											transition: 'border .24s ease-in-out',
																											marginBottom: "9px",
																										},
																										onTouchStart: (e) => {
																											console.log("onTouchStart");
																											e.target.onClick(e);
																										}
																									})}>
																										<input {...getInputProps()} />
																										<b className="text-center"
																											style={{ fontSize: "14px", color: "#000F" }}>{doc}</b>
																										<br />
																										<p>Drag 'n' drop some files here, or click to select files<br/>
																								(Only *.pdf, *.tif, *.bmp, *.jpg and *.png images will be accepted)</p>
																									</div>
																									<aside>
																										<ul className="fa-ul">
																											{this.state.files.filter(f => f.docName && f.docName === `${bh.name} - ${subDoc.name} - ${doc}`).map((uploadedFile, ufIdx) => (
																												this.fileItem(uploadedFile, ufIdx)
																											))}
																										</ul>
																									</aside>
																								</section>
																							)}
																						</Dropzone>
																					))}
																				</div>))}
																			{typeId !== "BASICCLAIMSREQUIREMENTS" && <Col>
																				<Label>DETAILS (OPTIONAL)</Label>
																				<Input type="text"
																					className="bg-white"
																					placeholder=""
																					name={`attachmentDetails${typeId}`}
																					data-bhname={bh.name}
																					onChange={this.handleChange}
																					value={this.state[`attachmentDetails`].find(a => a.name === bh.name)?.value ?? ""}
																				/>
																			</Col>}
																		</Col>}
																	</Row>
																</Card>
															</React.Fragment>
														)
													})}
											</div>
										</Col>
									</Row>

									{this.state.action !== "view" &&
										<Button color="success" className="pull-right"
											onClick={this.submitClaims}
										>Submit</Button>}
								</Col>
							</fieldset>
						</Row>
					</fieldset>
				</div>
			</>
		);
	}
}

export default ClaimSubmitForm;
