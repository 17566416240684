import React, { useState } from "react"
import axios from 'axios';
// reactstrap components
import {
	Row,
	Col,
	Button,
	Badge,
	Label,
	Input,
	Spinner
} from "reactstrap";


const TrackClaimStatus = (props) => {
	const [claimTrackingNo, setClaimTrackingNo] = useState("")
	const [claimDetails, setClaimDetails] = useState(null)
	const [errMsg, setErrMsg] = useState("")
	const [isFetching, setIsFetching] = useState(false)

	const clStatus = [{ "SCMA_OID": "CL_STATUS_FC", "CODE_DESC": "Fully Completed" },
	{ "SCMA_OID": "CL_STATUS_NC", "CODE_DESC": "New" },
	{ "SCMA_OID": "CL_STATUS_PC", "CODE_DESC": "Partially Completed" },
	{ "SCMA_OID": "CL_STATUS_PD", "CODE_DESC": "Pending" },
	{ "SCMA_OID": "CL_STATUS_PV", "CODE_DESC": "Pending Validation" },
	{ "SCMA_OID": "CL_STATUS_RA", "CODE_DESC": "Request Approval" },
	{ "SCMA_OID": "CL_STATUS_RC", "CODE_DESC": "Request Checking" }]


	const checkClaimDetails = async () => {
		//A1B15CBE738C
		setIsFetching(true)
		axios.get(`https://api.ulink.ins-link.com/cl-track/${claimTrackingNo}`)
			.then((response) => {
				console.log(response)
				setIsFetching(false)

				//clStatus
				if(response.data.Status){
					response.data.StatusDesc = clStatus.find(clst=>clst.SCMA_OID === "CL_STATUS_"+response.data.Status)?.CODE_DESC
				}

				setClaimDetails(response.data)
			}).catch(e=>{
				console.log(e.response)
				if(e?.response?.data?.error){
					setErrMsg(e.response.data.error)
				}
				setIsFetching(false)
			})
	}

	return (
		<div className="content">
			{isFetching && <Row className=" mx-0">
				<Col md="4" className="mx-auto text-center p-5 fade-in">
					<Spinner color="info" style={{ width: '3rem', height: '3rem' }} />
				</Col>
			</Row>}
			{claimDetails && <Row className=" mx-0">
				<Col md="4" className="mx-auto bg-light rounded border text-dark p-5 fade-in trackedClaimDetails">
					<Row className="">
						<Col xs="12">
							<h3>Claim Summary</h3>
						</Col>

						<Col xs="12">
							<Label>Claim Ref.#</Label>
							<Input type="input"
								className="border-0 font-weight-bold"
								value={claimDetails["Barcode"]}
								readOnly
							/>
						</Col>

						<Col xs="12">
							<Label>Receive Date</Label>
							<Input type="input"
								className="border-0 font-weight-bold"
								value={claimDetails["Receive Date"]}
								readOnly
							/>
						</Col>

						{claimDetails["StatusDesc"] && <Col xs="12">
							<Label>Status</Label>
							<Input type="input"
								className="border-0 font-weight-bold"
								value={claimDetails["StatusDesc"]}
								readOnly
							/>
						</Col>}

						<Col xs="12">
							<Label>Claimed Amount</Label>
							<Input type="input"
								className="border-0 font-weight-bold"
								value={claimDetails["Presented Amt (Original CCY)"] + " "+claimDetails["ccy"]}
								readOnly
							/>
						</Col>

						{claimDetails["StatusDesc"] &&  <Col xs="12">
							<Label>Approved Amount</Label>
							<Input type="input"
								className="border-0 font-weight-bold"
								value={(claimDetails["Status"] !== "FC" ) ? "": claimDetails["Claim Total Approved Amount"] + " "+claimDetails["ccy"]}
								readOnly
							/>
						</Col>}

						<Col xs="12">
							<Label>Payment Date</Label>
							<Input type="input"
								className="border-0 font-weight-bold"
								value={claimDetails["Payment Date"]}
								readOnly
							/>
						</Col>

						<Col xs="12" className="pt-4">
							<span className="text-info cursor-pointer" onClick={() => {
								setClaimDetails(null)
							}}>{"<< Back"}</span>
						</Col>
					</Row>
				</Col>
			</Row>}
			{!claimDetails && !isFetching && <Row className=" mx-0">
				<Col md="4" className="mx-auto bg-light rounded border text-dark p-5 fade-in">
					<Row className="">
						<Col md="12">
							<h3>Check Claim Status</h3>
						</Col>
						{errMsg !== "" && <Col md="12">
							<Badge color="danger">{errMsg}</Badge>
						</Col>}
						<div className="w-100"></div>
						<Col md="12" className="mb-0 pb-0">
							<Label>Claim Tracking Number</Label>
							<Input type="input" name="claimTrackingNo"
								style={{ height: "51px", fontSize:"2em" }}
								value={claimTrackingNo}
								onChange={e => setClaimTrackingNo(e.target.value)}
							/>
						</Col>
						<Col md="12" className="mt-0 pt-0">
							<Button block color="info" className="btn-lg mt-0"
								onClick={checkClaimDetails}
							><i className="fas fa-search"></i>  Track</Button>
						</Col>
					</Row>
					<Row>
						<Col md="12" className="text-center">
							- Or -
						</Col>
					</Row>
					<Row>
						<Col md="12">
							{/*
							 Goto OTP page, after OTP, take user to claim form
							*/}
							<Button block color="info"
								onClick={e => props.history.push("/guest/otpLogin")}
								className="btn-lg"><i className="fas fa-file-import"></i> Claim Submission</Button>
						</Col>
					</Row>
				</Col>
			</Row>}
		</div>
	)
}


export default TrackClaimStatus;
