import React, { useEffect, useState} from "react"
import {
    Col, Row, Label,
} from "reactstrap";
import store from 'store'
import {Gx} from "helper/ApiCaller.js"


const MpMenu = (props) => {

    const { setPageTitle } = props
    const [isPolicy, setIsPolicy] = useState(true)
    const [isFetching, setIsFetching] = useState(false)

    useEffect(() => {
       let user = store.get("user")

        console.log("user",user)

        if(user && user.idCardNo){
            setIsPolicy(true)
        }else{
            getPolicy().then(policy =>{
                if(policy && policy.length > 0){
                    if(!user.name){
                        let applicant = policy[0].policy.policyQuotation.insurredDetails.find(p=>p.isApplicant)
                        if(applicant){
                            store.set("user",fillApplicatToProfile(applicant,user))
                            setIsPolicy(true)
                        }
                    }
                }
            })
        }
    }, [])

    useEffect(() => {
        if(setPageTitle)
           setPageTitle("");
    }, [setPageTitle])

    
    const fillApplicatToProfile = (applicant, profile) =>{
        let profileClone = {...profile}
        profileClone.address = {
            line1: applicant.address1,
            line2: applicant.address2,
            line3: applicant.barangay,
            city: applicant.city,
            state: applicant.region,
            zipCode: applicant.postal,
            country : applicant.country,
        }
        profileClone.dob = applicant.dob
        profileClone.idCardNo = applicant.idNumber
        profileClone.gender = applicant.gender
        profileClone.email = {address: applicant.email}
        profileClone.marriageStatus = applicant.civilStatus
        profileClone.nationality = applicant.nationality
        profileClone.idCardType =  applicant.idType
        profileClone.occupation =  applicant.occupation
        profileClone.placeBirth =  applicant.placeBirth 
        profileClone.mobile =   {
                                    country : applicant.mobileNoInt,
                                    number :applicant.mobileNo
                                }
        
        profileClone.name = {
            family: applicant.lastName,
            first : applicant.firstName,
            mid : applicant.middleName,
            suffix : applicant.suffix,
        }
        return profileClone
    }

    const getPolicy = async ()  =>{
        let result = [];
        
         //let user = store.get("user");
        
         await Gx.post(`
                query {
                    policies(limit: 1, skip: 0, needsTotal: true, sort: "_id") {
                    policy {
                        policyQuotation {
                            insurredDetails {
                            refNo
                            type
                            isApplicant
                            mbrType
                            rate
                            dob
                            idType
                            firstName
                            lastName
                            middleName
                            suffix
                            addressType
                            address1
                            address2
                            address3
                            region
                            barangay
                            city
                            postal
                            province
                            country
                            mobileNo
                            mobileNoInt
                            alternativeNo
                            alternativeNoInt
                            email
                            occupation
                            gender
                            civilStatus
                            placeBirth
                            nationality
                            idNumber
                        }
                        }
                    }
                    }
                }
             `).then(json => {
                 if (json) {
                     const { data, errors } = json
                     result = data?.policies
                     const error = errors?.[0]
                     if (error) {
                         props.notify(errors[0].message.toString());
                     }
                 }
                 return null
             })
        
        return result;
    }

    return (
        <>
            {!isPolicy && !isFetching && <Row onClick={(e) => { props.history.push("/"); }}
                        className={"cursor-pointer border rounded p-2 mx-1 mx-md-5 my-2 bg-light"}>
                        <Col xs="3">
                            <div style={{ color: "var(--warning)", fontSize: "39.2px" }} className="text-center">
                                <i className="fas fa-exclamation-triangle"></i>
                            </div>
                        </Col>
                        <Col xs="9" style={{
                            color: "#168CB7", fontSize: "17px",
                            fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif'
                        }}>
                            Policy not found<br />
                            <Label style={{color:"#666",fontSize:"0.9em"}}>Please Purchase Our Product to start accessing our online service.</Label>
                        </Col>
                    </Row>}
            {props.routes
                .filter(link => link.isNav)
                .filter(link => link.layout === "/mp")
                .filter(link => ((link.isBkrOnly && this.isBroker) || !link.isBkrOnly))
                .filter(link => ((link.isAdmOnly && this.isAdmin) || !link.isAdmOnly))
                .filter(link => (isPolicy? true : (link.name === "Logout" || link.name === "Quotation" )))
                .map((link, key) => (
                    <Row key={link.name} onClick={(e) => { props.history.push(link.layout + link.path); }}
                        className={"cursor-pointer border rounded p-2 mx-1 mx-md-5 my-2"}>
                        <Col xs="3">
                            <div style={{ color: "#168CB7", fontSize: "39.2px" }} className={link.icon ? "text-center" : "d-none"}>
                                <i className={link.icon} id={`icon-${key}`}></i>
                            </div>
                        </Col>
                        <Col xs="9" style={{
                            color: "#168CB7", fontSize: "17px",
                            fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif'
                        }}>
                            {link.name}<br />
                            <Label style={{color:"#666",fontSize:"0.9em"}}>{link.desc ?? ""}</Label>
                        </Col>
                    </Row>
                ))}
        </>
    )
}

export default MpMenu