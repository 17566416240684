import axios from 'axios';
import store from 'store';
import 'isomorphic-fetch';

const jwt = require('jsonwebtoken');

const getUser = () => {
	let user = store.get("user")
	let guest = {
		token: '',// from graphql
		tokens: {
			access: {
				token: ""
			}
		}
	};

	if (!user) {
		user = guest;
	} else {
		let decoded = jwt.decode(user.token);
		//console.log("jwt exp",Date.now() - (decoded.exp * 1000))
		if (decoded === null || Date.now() >= decoded.exp * 1000) {
			store.remove("user");
			store.remove('permissions');
			store.remove('loggedIn');
			return guest;
		  }
	}
	return user
}

class Rx {
	constructor(hist) {
		this.history = hist ?? [];
	}

	get = async (url, params) => await axios.get(url,
		{
			params: { ...params },
			headers: { Authorization: `Bearer ${getUser().token}` }
		}).catch(function (err) {
			if (err?.response?.status === 401) {
				store.remove("user");
				store.remove('permissions');
				store.remove('loggedIn');
				this.history.push('/guest/login');
				throw err;
			} else {
				throw err;
			}
		}.bind(this))
}

class Tx {
	static post = async (url, payload = {}) => await axios.post(url,
		payload,
		{
			headers: { Authorization: `Bearer ${getUser().token}` }
		})

	static upload = async (url, payload = {}, onUploadProgress) => await axios.post(url,
		payload,
		{
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: `Bearer ${getUser().token}`
			},
			onUploadProgress,
		})

	static patch = async (url, payload = {}) => {
		return await axios.patch(url,
			payload,
			{
				headers: { Authorization: `Bearer ${getUser().token}` },
			})
	}

	static delete = async (url, payload = {}) => await axios.delete(url,
		{
			headers: { Authorization: `Bearer ${getUser().token}` },
			data: payload,
		})
}

class Gx {

	constructor(hist) {
		this.history = hist ?? [];
	}

	static post = async (query) => {
		const payload = {
			variables: null,
			query: this.toSingleLine(query),
		}
		let user = getUser()
		const opt = {
			method: 'POST',
			headers: new Headers({
				'Content-Type': 'application/json',
				'x-client-id': `${process.env.REACT_APP_API_URL_GRAPHQL_CLIENT_ID}`,
				// 'x-locale': `${user.locale}`,
				'x-access-token': `${user.token}`,
			}),
			body: JSON.stringify(payload),
		}
		return fetch(`${process.env.REACT_APP_API_URL_GRAPHQL}`, opt)
		.then(res => {
			if (res?.status === 401) {
				store.remove("user");
				store.remove('permissions');
				store.remove('loggedIn');
				this.history.push('/guest/login');
				return { data: null, errors: [{ message: res.status }] }
			}

			if (res?.status >= 400)
				return { data: null, errors: [{ message: res.status }] }
			return res ? res.json() : { data: {} }
		}).catch(error => {
			return { data: null, errors: [{ message: error.toString() }]}
		})
	}

	static toQlParams = (params) => {
		return Object.keys(params).reduce((s, k) => {
			var val = params[k]
			if (Array.isArray(val)) {
				val = val.map(va => {
					var v = null
					v = JSON.stringify(va)//.replace(/\"/g, '\\\"')
					if (v?.startsWith("{\"")) {
						v = `{ ${Gx.toQlParams(va)} }`
					}
					return v
				})
				var v = `[ ${val.join(' ')} ]`
				s.push(`${k}:${v}`)
			}
			else {
				var v = null
				v = JSON.stringify(val)//.replace(/\"/g, '\\\"')
				if (v?.startsWith("{\"")) {
					v = `{ ${Gx.toQlParams(val)} }`
				}
				s.push(`${k}:${v}`)
			}
			return s
		}, []).join(' ')
	}

	static fetchGraphWithVars = (variables, query, debug = false) => {
		//
		// ref: https://github.com/jaydenseric/graphql-multipart-request-spec
		//
		const formData = new FormData()
		let vars = {}
		let files = []
		let user = getUser()
		for (let varName in variables) {
			let value = variables[varName]
			if (value instanceof File) {
				const id = Math.random().toString(36);
				files.push({ id, varName, file: value })
				variables[varName] = null
			} else {
				vars[varName] = value
			}
		}
		let operations = {
			query: this.toSingleLine(query),
			variables: vars,
		}
		formData.append('operations', JSON.stringify(operations))
		let map = {}
		files.forEach((meta) => {
			let { id, varName, file } = meta
			map[id] = [`variables.${varName}`]
		})
		formData.append('map', JSON.stringify(map))
		files.forEach((meta) => {
			let { id, varName, file } = meta
			formData.append(id, file)
		})
		if (debug) console.log(formData)
		const url = `${process.env.REACT_APP_API_URL_GRAPHQL}`
		const headers = {
			'x-client-id': `${process.env.REACT_APP_API_URL_GRAPHQL_CLIENT_ID}`,
		}
		if (user.token) {
			headers['x-access-token'] = `${user.token}`
			// headers['Authorization'] =  `Bearer ${Session.accessToken}`
		}
		if (window?.Session?.user?.locale) {
			headers['x-locale'] = window?.Session?.user?.locale
		}
		const opt = {
			method: 'POST',
			headers: new Headers({ ...headers }),
			body: formData,
		}
		return fetch(url, opt).then(res => {
			if (res.status >= 400)
				return { data: null, errors: [{ message: res.status }] }
			return res ? res.json() : { data: {} }
		}).catch(error => {
			return { data: null, errors: [{ message: error.toString() }]}
		})
	}


	static toSingleLine = (s) => {
		return s.replace(/[\n\s]+/g, ' ')
	}

	static unitTest = async () => {
		let signInTest = async (data) => {
			const { username, password } = data
			return Gx.post(`mutation {
				signIn(
					${Gx.toQlParams({ username, password })}
				) {
					username token name { short } 
				}
			}`).then(json => {
				if (json) {
					const { data, errors } = json
					const profile = data?.signIn
					const error = errors?.[0]
					if (!error) {
						store.set("user", profile);
						return profile
					}
				}
				return null
			})
		}
		return await signInTest({ username: 'badjeff@gmail.com', password: '1' });
	}

}
// (async () => { console.log( (await Gx.unitTest()) ) })()

export { Rx, Tx, Gx }