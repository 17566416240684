import { React, useEffect , useState} from "react"
import { Redirect } from "react-router-dom"
import {
    Row, Col, Label, Input, Card, Button, CustomInput, Badge, Spinner
} from "reactstrap";
import store from 'store'
import { Rx, Gx } from "helper/ApiCaller.js";

const Logout = (props) => {

    let [loggedOut, setLoggedOut] = useState(false)

    useEffect(() => {
        if (store.get("user")) {

            let { username, ...rest } = store.get("user")

            Gx.post(`mutation {
            signOut(
                ${Gx.toQlParams({ username })}
            ) {
                success error
            }
        }`).then(json => {
                if (json) {
                    const { data, errors } = json
                    const result = data?.signOut
                    const error = errors?.[0]
                    if (!error && result.success) {
                        store.remove("user");
                        store.remove('permissions');
                        store.remove('loggedIn');
                    } else {
                        console.log(error.message.toString());
                    }
                }
                setLoggedOut(true)
                return null
            })
        }
    }, [])


    console.log("here");

    if (store.get("user")) {
        return (

            <Row className="web-summary">
                <Col xs="12" className="mx-auto mt-5 text-center">
                    <Spinner color="info" style={{ width: '3rem', height: '3rem' }} />
                </Col>
            </Row>

        )
    }
    return <Redirect to="/" />
}

export default Logout