import TrackClaimStatus from "views/TrackClaimStatus";
import ClaimSubmitForm from "views/ClaimSubmitForm.js"; 
import OtpLogin from "views/OtpLogin.js"; 
import Logout from "views/Logout.js"; 
import MpMenu from "views/MpMenu.js"; 


var routes = [
 
 {
    path: "/otpLogin/",
    name: "OtpLogin",
    component: OtpLogin,
    layout: "/guest",
  },
 {
    path: "/trackClaimStatus/",
    name: "TrackClaimStatus",
    component: TrackClaimStatus,
    layout: "/guest",
  },
  {
    path: "/menu",
    name: "Menu",
    component: MpMenu,
    layout: "/mp",
	  isNav:false,
    isBkrOnly:false,
  },  

  {
    path: "/claims/:action",
    name: "Claim Submit",
    component: ClaimSubmitForm,
    layout: "/mp",
	  isNav:false,
    isBkrOnly:false,
  },
  {
    path: "/claims/:action/:claimId",
    name: "Claim Submit",
    component: ClaimSubmitForm,
    layout: "/mp",
	  isNav:false,
    isBkrOnly:false,
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
    layout: "/mp",
    icon:"fas fa-sign-out-alt",
    desc:"Logout to portal",
	  isNav:true,
    isBkrOnly:false,
  },
];
export default routes;
